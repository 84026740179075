// @ts-nocheck
import { Component } from "react";
import { Table, Tabs, Tag, Timeline, Collapse, Empty, List } from "antd";
import ExpandableContent from './ExpandableContent'
import { isObject } from "util";

const { Panel } = Collapse
const { TabPane } = Tabs;

export class NetworkBehavior extends Component<any, any> {
  render() {
    const { network } = this.props
    // console.log('pe props', this.props)
    if (!network) {
      return <Empty description={false} />
    }
    if (Object.keys(network).length === 0) {
      return <Empty description={false} />
    }

    // dns
    const { dns } = network
    const columnsDns = [
      {
        title: '域名',
        key: 'domain',
        dataIndex: 'domain',
      },
      {
        title: '地址',
        key: 'ips',
        render: (record) => {
          return record.ips.join(' ') || '-'
        },
      },
    ]

    // 处理 DNS 数据
    const dnsRecords = [];
    if (isObject(dns) && isObject(dns.domain2hosts)) {
      Object.entries(dns.domain2hosts).forEach(([domain, hosts]) => {
        dnsRecords.push({
          domain,
          ips: hosts,
        });
      });
    }

    // http
    const { http } = network
    const columnsHttp = [
      {
        title: 'URL',
        key: 'url',
      },
      {
        title: '方法',
        key: 'method',
      },
    ]

    // tcp
    const { tcp } = network
    const columnsTcp = [
      {
        title: '源地址',
        key: 'srcip',
        render: (record) => {
          return `${record.srcip}:${record.srcport}`
        },
      },
      {
        title: '目的地址',
        key: 'dstip',
        render: (record) => {
          return `${record.dstip}:${record.dstport}`
        },
      },
      {
        title: '发包(bytes)',
        key: 'tx_size',
        dataIndex: 'tx_size',
      },
      {
        title: '收包(bytes)',
        key: 'rx_size',
        dataIndex: 'rx_size',
      },
    ]

    // udp
    const { udp } = network
    const columnsUdp = [
      {
        title: '源地址',
        key: 'srcip',
        render: (record) => {
          return `${record.srcip}:${record.srcport}`
        },
      },
      {
        title: '目的地址',
        key: 'dstip',
        render: (record) => {
          return `${record.dstip}:${record.dstport}`
        },
      },
      {
        title: '发包(bytes)',
        key: 'size',
        dataIndex: 'size',
      },
    ]


    return (
      <div className="network-behavior">
        {/* <div className="header">
          <span className="header-name">PE信息</span>
          <span className="english-name">PE Info</span>
        </div> */}
        <div className="tabs-container">
          <div className="card-container">
            <Collapse defaultActiveKey={['1', '2', '3', '4']} ghost>
              <Panel header={`DNS (${dnsRecords?.length || 0})`} key="1">
                <Table rowKey={(record) => `${record.ts}_${Math.random()}`} columns={columnsDns} dataSource={dnsRecords} pagination={{ hideOnSinglePage: true }} />
              </Panel>
              <Panel header={`HTTP (${http?.length || 0})`} key="2">
                {http?.length === 0 ? <Empty description={false} /> :
                  <Table rowKey={(record) => `${record.ts}_${Math.random()}`} columns={columnsHttp} dataSource={http} pagination={{ hideOnSinglePage: true }} />}
              </Panel>
              <Panel header={`TCP (${tcp?.length || 0})`} key="3">
                {tcp?.length === 0 ? <Empty description={false} /> :
                  <Table rowKey={(record) => `${record.ts}_${Math.random()}`} columns={columnsTcp} dataSource={tcp} pagination={{ hideOnSinglePage: true }} />}
              </Panel>
              <Panel header={`UDP (${udp?.length || 0})`} key="4">
                {udp?.length === 0 ? <Empty description={false} /> :
                  <Table rowKey={(record) => `${record.ts}_${Math.random()}`} columns={columnsUdp} dataSource={udp} pagination={{ hideOnSinglePage: true }} />}
              </Panel>
            </Collapse>

          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import './css/bootstrap.css'
import './css/detail.css'
import './css/zTreeStyle.css'
import './css/dataTables.css'
import './css/global.css'
import 'antd/dist/antd.css';

import store from "./store";

import { Content, Header, Footer } from './layout';
import routes from './router'
import { AuthProvider } from './context/AuthContext';
import { Provider } from 'react-redux';

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider locale={zhCN}>
            <Provider store={store}>
                <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    <AuthProvider>

                        <Header></Header>
                        <div id='drawer-container' className='layout-container'>
                            <Router>
                                <Switch>
                                    <Content routes={routes}></Content>
                                </Switch>
                            </Router>
                        </div>


                        <Footer></Footer>
                    </AuthProvider>
                </div>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();

import React from 'react';
import {Input, Button, message, Form, Checkbox, Tabs} from "antd";
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import './css/login.css'
import axios from "axios";

const {TabPane} = Tabs;
const token = "ACCESS_TOKEN"

function setToken(value: string) {
    sessionStorage.setItem(token, value)
}

export function getToken() {
    // console.log('login getToken')
    return sessionStorage.getItem(token)
}

// export  function deleteToken(){
//     return localStorage.removeItem(token)
// }

export default class Login extends React.Component {
    state = {
        password: '',
        username: '',
    }
    submitHandle = () => {
        if (this.state.username === '' || this.state.password === '') {
            message.info('用户名密码不能为空')
        } else {
            let data = {username: this.state.username, password: this.state.password}
            console.log(data)
            axios.post(process.env.REACT_APP_API_ENDPOINT + 'main/login', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then((response: any) => {
                    if (response.data.code == 200 && response.data.msg == "登录成功") {
                        console.log(response)
                        setToken(response.data.token)
                        message.info(response.data.msg)

                        // @ts-ignore
                        this.props.history.push('/list')
                    } else {
                        console.log(response.data.msg)
                        message.info(response.data.msg)
                    }
                }).catch(function (error) {
                console.log(error.message)
            })
        }

    };
    handleChangeUsername = (e: { target: { value: any; }; }) => {
        this.setState({
            username: e.target.value
        })
    }
    handleChangePwd = (e: { target: { value: any; }; }) => {
        this.setState({
            password: e.target.value
        })
    }
    onFinish = (values: any) => {
        console.log('Received values of form: ', values);
    }

    render() {
        return (
            <main className="login-page" style={{marginTop: "-64px"}}>
                <img className="bg" src='assets/images/register.png'/>
                <img className="bg2" src='assets/images/pgkuang.png'/>

                <div className="login-top">
                    用户登录
                </div>
                <div className='login-main'>
                    <Form onFinish={this.onFinish} className="login-form" initialValues={{remember: true}}>
                        <Form.Item
                            name="username"
                            rules={[{required: true, message: '请输入用户名!'}]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="请输入登录ID"
                                   value={this.state.username}
                                   onChange={this.handleChangeUsername}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{required: true, message: '请输入密码!'}]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                type="password"
                                value={this.state.password}
                                placeholder="请输入密码"
                                onChange={this.handleChangePwd}
                            />
                        </Form.Item>

                        <Form.Item className='remember-item'>
                            <Checkbox className='login-rember' checked={true}>记住密码</Checkbox>

                            <a className="login-form-forgot" href="/#/setpwd">
                                忘记密码?
                            </a>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button"
                                    onClick={this.submitHandle}
                            >
                                登录
                            </Button>
                            <div className='register-btn'>
                                <UserOutlined className='UserOutlined'/>
                                <a href="/#/Register">注册账户</a>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </main>


        );
    }
}

// @ts-nocheck
import React, { useContext } from 'react';
import { Input, Button, message, Form, Checkbox, Tabs, Typography } from "antd";
import { UserOutlined, LockOutlined, CodeSandboxOutlined } from '@ant-design/icons';
import '../css/login.css'
import axios from '../api/index'
import { encryptPwd } from '../utils/encrypt';
import {
    AuthContext,
    setToken,
    getToken,
} from '../context/AuthContext';
import ForgetPasswordModal from './ForgetPasswordModal';


export default class Login extends React.Component {
    state = {
        password: '',
        username: '',
        // email: '',
        forgetPasswordVisible: false,
    }
    submitHandle = () => {
        if (this.state.username === '' || this.state.password === '') {
            message.info('用户名密码不能为空')
        } else {
            let data = { username: this.state.username, password: encryptPwd(this.state.password) }
            console.log(data)
            axios.post(process.env.REACT_APP_API_ENDPOINT + '/user/login', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then((response: any) => {
                    if (response.status == 201) {
                        console.log(response)
                        const { access_token, refresh_token } = response.data
                        setToken(access_token, refresh_token)
                        message.success('登录成功')
                        this.context.login(this.state.username)

                        // @ts-ignore
                        this.props.history.push('/')
                    } else {
                        console.log(response.data.msg)
                        // message.info(response.data.msg)
                    }
                }).catch(function (error) {
                    message.error(error?.response?.data?.message)
                })
        }

    };
    handleChangeUsername = (e: { target: { value: any; }; }) => {
        this.setState({
            username: e.target.value
        })
    }
    handleChangeEmail = (e: { target: { value: any; }; }) => {
        this.setState({
            email: e.target.value
        })
    }
    handleChangePwd = (e: { target: { value: any; }; }) => {
        this.setState({
            password: e.target.value
        })
    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.submitHandle()
        }
    }
    onFinish = (values: any) => {
        console.log('Received values of form: ', values);
    }

    componentDidMount() {
        if (getToken()) {
            this.context.logout()
        }
    }

    showForgetPasswordModal = () => {
        this.setState({ forgetPasswordVisible: true });
    };

    handleForgetPasswordChange = async (values) => {
        // 处理修改密码成功后的逻辑
        this.setState({ forgetPasswordVisible: false });
    };

    render() {
        return (
            <main className="login_wrap">
                <div className='login_title'>
                    <CodeSandboxOutlined className='login_title_icon' />
                    <div>
                        <div className='login_title_cn'>工控情报中心</div>
                        <div className='login_title_en'>ICS Threat Intelligence Center</div>
                    </div>
                </div>

                {/* <div className="login-top">
                    用户登录
                </div> */}
                <div style={{height: '527px'}}>
                    <div className='login-main'>
                        <div className='greet'>用户登录</div>
                        <Form onFinish={this.onFinish} className="login-form" layout='vertical'>
                            <Form.Item
                                label="用户名"
                                name="username"
                                rules={[{ required: true, message: '请输入用户名!' }]}
                            >
                                <Input placeholder="用户名"
                                    value={this.state.username}
                                    onChange={this.handleChangeUsername}
                                />
                            </Form.Item>
                            <Form.Item
                                label="登录密码"
                                name="password"
                                rules={[{ required: true, message: '请输入登录密码!' }]}
                            >
                                <div>
                                    <Input.Password
                                        value={this.state.password}
                                        placeholder="请输入密码"
                                        onChange={this.handleChangePwd}
                                    />
                                    <Typography.Link style={{
                                        position: 'absolute',
                                        top: '-28px',
                                        right: '-6px'
                                    }} onClick={this.showForgetPasswordModal}>忘记密码？</Typography.Link>
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button"
                                    onClick={this.submitHandle}
                                >
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div style={{textAlign: 'center'}}>还没有账号？<a href="/#/register">去注册</a></div>
                </div>
                <ForgetPasswordModal
                    visible={this.state.forgetPasswordVisible}
                    onOk={this.handleForgetPasswordChange}
                    onCancel={() => this.setState({ forgetPasswordVisible: false })}
                />
            </main>
        );
    }
}

Login.contextType = AuthContext; 
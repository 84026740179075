import axios from 'axios'
import { Content } from '../layout';
import { message } from "antd";
import { getToken, handleTokenRefresh } from '../context/AuthContext';

const instance = axios.create({
    timeout: 5000,
    baseURL: '/api/v1',
})

// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// 添加请求拦截器
instance.interceptors.request.use(
    config => {
        // 将 token 添加到请求头
        const token = getToken()
        token && (config.headers.Authorization = 'Bearer ' + token)
        // console.log('请求拦截', config, token)
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 添加响应拦截器
instance.interceptors.response.use(
    response => {
        // console.log('response', response)
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    },
    error => {
        // 相应错误处理
        console.log('api error', error?.response?.config?.url)
        // 比如： token 过期， 无权限访问， 路径不存在， 服务器问题等
        switch (error.response?.status) {
            case 400:
                break
            case 401:
                handleTokenRefresh()
                break
            case 403:
                break
            case 404:
                if(!(error?.response?.config?.url ?? '').startsWith('/analysis')) {
                    message.error('【404】无法从服务器中获取到信息')
                }
                break
            case 500:
                break
            default:
                console.log('其他错误信息')
        }
        return Promise.reject(error)
    }
)

export default instance

export const handleDownload = async (fileUrl) => {
    try {
        const response = await instance.get(fileUrl, {
            responseType: 'blob', // 重要：指定响应类型为blob
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        });

        if (response.status !== 200) {
            throw new Error(`An error occurred while fetching the file: ${response.statusText}`);
        }

        // 从响应头中获取文件名
        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition ? getFileNameFromHeader(contentDisposition) : '样本';

        // 创建临时URL和隐藏的链接元素以触发下载
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
        const a = document.createElement('a');
        a.href = url;
        // console.log('response', response)
        a.download = fileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        message.success('下载成功')
    } catch (error) {
        console.error('Download failed:', error);
        // 处理错误逻辑
    }
};

function getFileNameFromHeader(contentDisposition) {
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = contentDisposition.match(fileNameRegex);
    if (matches != null && matches[1]) { // matches[1] contains the captured group
        // Remove surrounding quotes if present
        let fileName = matches[1].replace(/['"]/g, '');
        // Decode any URL encoding if necessary
        fileName = decodeURIComponent(fileName);
        return fileName;
    }
    return null; // Or provide a fallback filename here
}


import { createStore } from "redux";

import { SET_SEARCH_QUERY, SET_SEARCH_TAG, SET_SEARCH_FAMILY, VOTE_SUP, SET_USERNAME, SET_CREATED_ON_START, SET_CREATED_ON_END, SET_SCORE_START, SET_SCORE_END } from './actions';



let initial = {
    supNum: 10,
    oppNum: 5,
    searchQuery: '',
    tag: undefined,
    family: undefined,
    username: '',
    created_on_start: '',
    created_on_end: '',
    scoreStart: '',
    scoreEnd: '',
};



const reducer = function reducer(state = initial, action) {
    // 浅拷贝，不直接修改容器中的状态,而是在return 时再修改
    state = { ...state };
    switch (action.type) {
        case VOTE_SUP:
            state.supNum++;
            break;
        case "VOTE_OPP":
            state.oppNum++;
            break;
        case SET_SEARCH_QUERY:
            // return { ...state, searchQuery: action.payload };
            state.searchQuery = action.payload
            break;
        case SET_SEARCH_TAG:
            state.tag = action.payload
            break;
        case SET_SEARCH_FAMILY:
            state.family = action.payload
            break;
        case SET_USERNAME:
            state.username = action.payload
            break;
        case SET_CREATED_ON_START:
            state.created_on_start = action.payload
            break;
        case SET_CREATED_ON_END:
            state.created_on_end = action.payload
            break;
        case SET_SCORE_START:
            state.scoreStart = action.payload
            break;
        case SET_SCORE_END:
            state.scoreEnd = action.payload
            break;
        default: break;
    }
    return state;
};
const store = createStore(reducer);

export default store;
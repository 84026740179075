import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchQuery, setSearchTag, setSearchFamily, voteSup, setCreatedOnStart, setCreatedOnEnd, setScoreStart, setScoreEnd } from '../store/actions';
import { Button, Input, Drawer, Form, Select, DatePicker } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import axios from '../api/index';
import { URLTagsFamilies } from '../api/config';
import { go } from '../utils';
import moment from "moment";

const HeaderSearch = function HeaderSearch() {
  const dispatch = useDispatch();
  const { searchQuery, tag, family, created_on_end, created_on_start, scoreStart, scoreEnd } = useSelector(state => state);

  const { Option } = Select;
  const { RangePicker } = DatePicker;

  // 使用 useState 来管理输入框的值
  const [inputValue, setInputValue] = useState(searchQuery);
  const [createdOnStartValue, setCreatedOnStartValue] = useState(created_on_start);
  const [createdOnEndValue, setCreatedOnEndValue] = useState(created_on_end);
  const [rangeValue, setRangeValue] = useState(() => {
    // console.log('useState声明的createdOnStartValue', createdOnStartValue, moment(createdOnStartValue))
    if (createdOnStartValue && createdOnEndValue) {
      return [moment(createdOnStartValue), moment(createdOnEndValue)]
    } else {
      return null
    }
  });
  const [scoreStartValue, setScoreStartValue] = useState(scoreStart);
  const [scoreEndValue, setScoreEndValue] = useState(scoreEnd);

  // 状态变量用于存储从接口获取的数据
  const [familyOptions, setFamilyOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState(family);
  const [selectedTag, setSelectedTag] = useState(tag);

  const dispatchActions = () => {
    // console.log('dispatchActions', scoreValue);
    dispatch(setSearchQuery(inputValue));
    dispatch(setSearchTag(selectedTag));
    dispatch(setSearchFamily(selectedFamily));
    dispatch(voteSup());//暂时用来触发store更新的
    dispatch(setCreatedOnStart(createdOnStartValue));
    dispatch(setCreatedOnEnd(createdOnEndValue));
    dispatch(setScoreStart(scoreStartValue));
    dispatch(setScoreEnd(scoreEndValue));
  }

  const handleSearch = () => {
    console.log('Search Query:', inputValue, scoreStartValue);
    dispatchActions()
    go('/search')
    onClose()
  };

  const handleSearchChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleRangePickerChange = (dates) => {
    // console.log('handleRangePickerChange', dates)
    const [startDate, endDate] = dates;
    setCreatedOnStartValue(startDate.format('YYYY-MM-DDTHH:mm:ss[Z]'));
    setCreatedOnEndValue(endDate.format('YYYY-MM-DDTHH:mm:ss[Z]'));
    setRangeValue(dates)
  };

  const handleChangeScoreStart = (event) => {
    setScoreStartValue(event.target.value);
  };
  const handleChangeScoreEnd = (event) => {
    setScoreEndValue(event.target.value);
  };

  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleAdvancedSearch = () => {
    if (open) {
      setOpen(false);
    } else {
      onOpen();
    }
  };

  // 发送请求获取家族选项
  useEffect(() => {
    axios(URLTagsFamilies)
      .then(response => {
        const data = response.data;
        const families = data.families;
        const tags = data.tags;
        setFamilyOptions(families);
        setTagOptions(tags);
      })
      .catch(error => console.error('Error fetching family options:', error));
  }, []);

  const handleFamilyChange = (value) => {
    setSelectedFamily(value);
  };

  const handleTagChange = (value) => {
    setSelectedTag(value);
  };

  const handleResetSearch = () => {
    resetQueryParams()
    // dispatchActions()
  };

  const resetQueryParams = () => {
    setSelectedFamily(undefined)
    setSelectedTag(undefined)
    setCreatedOnStartValue('');
    setCreatedOnEndValue('');
    setRangeValue(null)
    setScoreStartValue('')
    setScoreEndValue('')
  };

  // 监听路由，跳到其他就清空drawer
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentPath(window.location.pathname);
      const hash = window.location.hash
      if (hash !== '#/search') {
        resetQueryParams()
        onClose()
      }
    };
    window.addEventListener('popstate', handleRouteChange); // 监听浏览器前进后退按钮
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  const dom = document.getElementById('drawer-container')

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Input
          prefix={<SearchOutlined style={{ color: focus ? '#051747' : 'rgba(255, 255, 255, 0.45)' }} />}
          placeholder="搜索文件Hash (SHA256)"
          value={inputValue}
          onChange={handleSearchChange}
          onPressEnter={handleSearch}
          className={focus ? 'header_search_input bkg_white color_dark' : 'header_search_input'}
          onFocus={handleFocus}
          onBlur={handleBlur}
          allowClear
        />
        <Button type="text" icon={<FilterOutlined />} style={{ color: 'white' }} onClick={handleAdvancedSearch}>高级搜索</Button>
      </div>
      <Drawer
        title="高级搜索"
        placement="top"
        width={720}
        onClose={onClose}
        visible={open}
        contentWrapperStyle={open ? { height: '160px', 'marginTop': '65px' } : { height: '160px' }}
        getContainer={dom}
      >
        <Form
          layout={'inline'}
        >
          <Form.Item label="家族" name="selectedFamily">
            <Select
              placeholder="选择家族"
              value={selectedFamily}
              onChange={handleFamilyChange}
              style={{ width: 200 }}
              allowClear
              showSearch
              filterOption={true}
            >
              {familyOptions.map((option) => (
                <Option key={option}>{option}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="标签">
            <Select
              placeholder="选择标签"
              value={selectedTag}
              onChange={handleTagChange}
              style={{ width: 200 }}
              allowClear
            >
              {tagOptions.map((option) => (
                <Option key={option}>{option}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="提交时间">
            <RangePicker allowClear showTime
              format="YYYY/MM/DD HH:mm:ss" style={{ width: '240px' }} onChange={handleRangePickerChange} value={rangeValue} />
          </Form.Item>
          <Form.Item label="恶意分数">
            <Input.Group compact>
              <Input style={{ width: 90, textAlign: 'center' }} placeholder="最低分" onChange={handleChangeScoreStart} value={scoreStartValue} onPressEnter={handleSearch} />
              <Input
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                  backgroundColor: 'white',
                }}
                placeholder="~"
                disabled
              />
              <Input
                style={{
                  width: 90,
                  textAlign: 'center',
                  borderLeft: 'none',
                }}
                placeholder="最高分"
                onChange={handleChangeScoreEnd}
                value={scoreEndValue}
                onPressEnter={handleSearch}
              />
            </Input.Group>
          </Form.Item>
          <Form.Item>
            <Button type="" onClick={handleResetSearch}>重置</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSearch}>搜索</Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default HeaderSearch;
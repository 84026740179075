// @ts-nocheck
import React from 'react';
import { Card, Collapse, List, Typography, Tag, Row, Col, Table, Button, message, Popconfirm, Space } from 'antd';
import { CaretRightOutlined, ExclamationCircleFilled } from '@ant-design/icons';

const { Panel } = Collapse;
const { Text } = Typography;

const colorMap = {
  高危: 'rgba(255, 241, 240, 1)', // 高危红色
  可疑: 'rgb(255 231 212)', // 可疑橙色
  通用: 'rgba(255, 251, 230, 1)', // 通用淡黄色
};

const fontColorMap = {
  高危: 'rgba(207, 19, 34, 1)', // 高危红色
  可疑: 'rgb(207 88 19)', // 可疑橙色
  通用: 'rgba(212, 136, 6, 1)', // 通用淡黄色
};

const fixedOrder = ['高危', '可疑', '通用'];

function getOrderedValues(categorizedSignatures: Record<string, any[]>, fixedOrder: string[]): [string, any[]][] {
  return fixedOrder.filter(category => category in categorizedSignatures).map(category => [category, categorizedSignatures[category]]);
}

class StaticFeature extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props', props)
    this.state = {
      signatures: props.signatures || [],
      iocsDisplayRange: {}, // 新增状态来跟踪每个signature的ioc显示范围
    };
  }

  componentDidUpdate(prevProps) {
    // 检查props.signatures是否发生了变化
    if (prevProps.signatures !== this.props.signatures) {
      // 如果发生变化，更新state
      this.setState({ signatures: this.props.signatures });
    }
  }

  renderIocItem = (obj) => {
    // console.log('ioc', obj)
    const ioc = obj.ioc
    return <List.Item key={Math.random().toString(36).slice(-8)}>
      {Object.entries(ioc).map(([key, value]) => (
        <div key={`${ioc}-${key}`}>
          <Text strong>{key}:</Text> {value}
          <br /> {/* 添加换行以便于阅读，或根据需要调整样式 */}
        </div>
      ))}
    </List.Item>
  };

  onLoadMore = (sigIndex) => {
    this.setState(prevState => {
      const newIocsDisplayRange = { ...prevState.iocsDisplayRange };
      newIocsDisplayRange[sigIndex] = {
        // 假设每次加载5个，可以根据需要调整
        start: (newIocsDisplayRange[sigIndex]?.start || 0) + 5,
      };
      return { iocsDisplayRange: newIocsDisplayRange };
    });
  };

  render() {
    const { signatures } = this.state;

    const categorizedSignatures = signatures.reduce((acc, sig) => {
      let category;
      if (sig.score === 10) category = '高危';
      else if (sig.score >= 7 && sig.score <= 9) category = '可疑';
      else category = '通用';
      if (!acc[category]) acc[category] = [];
      acc[category].push(sig);
      return acc;
    }, {});
    // console.log('categorizedSignatures', categorizedSignatures)

    const columns = [
      {
        title: '规则名',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
      },
      {
        title: '描述',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
      },
      {
        title: '标签/家族',
        key: 'tag_family',
        render: (record) => (
          <>
            {record.tags.length > 0 ? record.tags.map((tag) => (<Tag key={tag} color={'gold'}>{tag}</Tag>
            )) : '-'}
            <span style={{ margin: '0 5px' }}>/</span>
            {record.family ? <Tag color={'volcano'} style={{ marginLeft: '7px' }}>{record.family}</Tag> : '-'}
          </>
        ),
      },
      {
        title: '分数',
        dataIndex: 'score',
        key: 'score',
        width: 100,
      },
    ];


    return (
      <>
        {getOrderedValues(categorizedSignatures, fixedOrder).map(([category, sigs], index) => (
          <Card
            key={index}
            headStyle={{ backgroundColor: colorMap[category], marginBottom: 16, color: fontColorMap[category], fontWeight: 'bold', fontSize: 16, letterSpacing: 1 }}
            title={(<Space><ExclamationCircleFilled /><span>{category.charAt(0).toUpperCase() + category.slice(1)}特征</span><span style={{ letterSpacing: 0 }}>({sigs.length})</span></Space>)}
            bodyStyle={{ padding: 0 }}
            style={{ marginBottom: 33 }}
            bordered={false}
          >
            <Table
              rowKey={(record) => `${record.name}_${record.description}`}
              dataSource={sigs}
              columns={columns}
            />
          </Card>
        ))}

      </>
    );
  }
}

export default StaticFeature;
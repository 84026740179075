// @ts-nocheck
import { Component } from 'react';
import { Form, Input, DatePicker, Select, Table, Button, message, Popconfirm, Space, Tag, Row, Col, Avatar, Menu, Typography, Radio, Divider, Modal } from 'antd';
import axios from '../api/index'
import { URLTagsFamilies, URLDeleteAnalysis, URLUserTask, URLUserInfo, URLUserEmail } from '../api/config'
import { formatDate } from '../utils/index';
import { Link, withRouter } from 'react-router-dom';
import surveyFillIcon from "../images/Icon/survey-fill.svg";
import contactsBookFillIcon from "../images/Icon/contacts-book-fill.svg";
import lockFillIcon from "../images/Icon/lock-fill.svg";
import { SearchOutlined, CheckCircleOutlined, UserOutlined, CrownOutlined } from '@ant-design/icons';
import jwtDecode from 'jwt-decode';
import { go } from '../utils';
import type { FormInstance } from 'antd/es/form';
import React from 'react'
import ChangePasswordModal from './ChangePasswordModal';


function decodeToken(token: string): any {
    try {
        const decoded = jwtDecode(token);
        return decoded;
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
}

const { Option } = Select;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

class TaskCenter extends Component {
    formRef = React.createRef<FormInstance>();
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tags: [],
            families: [],
            scoreStart: '',
            scoreEnd: '',
            createdOn: [],
            family: '',
            tag: '',
            data: [],
            total: 0,
            currentPage: 1,
            pageSize: 10,
            currentRow: null,
            current: 'submitHistory',
            username: "",
            email: "",
            description: "",
            answer: "",
            question: "",
            phone: "",
            visible: false,
            countdown: 0,
            canSend: true,
            newEmail: '',
            userId: undefined,
            changePhoneVisible: false,
            newPhone: '',
            changePasswordVisible: false,
        };
    }

    componentDidMount() {
        // this.fetchTagsFamilies();
        this.fetchTasks(1);
        this.fetchUserInfo();
    }

    fetchTagsFamilies = async () => {
        const response = await axios.get(URLTagsFamilies);
        this.setState({
            tags: response.data.tags,
            families: response.data.families,
        });
    };

    fetchTasks = async (page) => {
        this.setState({ loading: true });
        console.log('this', this.state.score)
        let created_on_start = ''
        let created_on_end = ''
        if (this.state.createdOn && this.state.createdOn.length === 2) {
            const [startDate, endDate] = this.state.createdOn;
            created_on_start = startDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
            created_on_end = endDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
        }
        const response = await axios.get(URLUserTask, {
            params: {
                page,
                page_size: this.state.pageSize,
                // ordering: 'created_on',
                created_on_start: created_on_start,
                created_on_end: created_on_end,
                score_start: this.state.scoreStart,
                score_end: this.state.scoreEnd,
                // family: this.state.family,
                // tag: this.state.tag,
            },
        });
        this.setState({
            loading: false,
            data: response.data.results,
            total: response.data.count,
            currentPage: page,
        });
    };

    handleSearch = async (values) => {
        this.setState({ createdOn: values.createdOn, score: values.score, family: values.family, tag: values.tag }, () => {
            this.fetchTasks(1);
        });
    };

    handleTableChange = (pagination) => {
        this.setState(
            {
                currentPage: pagination,
            },
            () => {
                this.fetchTasks(this.state.currentPage);
            },
        );
    };

    handleDelete = async (id) => {
        await axios.get(URLDeleteAnalysis(id));
        message.success('删除成功')
        this.fetchTasks(this.state.currentPage);
    };

    handleViewDetails = (id) => {
        console.log('this', this)
        this.props.history.push(`/detail/${id}`)
        console.log(`查看详细信息：ID ${id}`);
    };

    handleSorterChange = (pagination, filters, sortedInfo) => {
        // sortedInfo包含排序的字段名和排序方式（'ascend' 或 'descend'）
        const { field, order } = sortedInfo;
        console.log('sortedInfo', sortedInfo)
        let ordering = ''
        if (order) {
            if (order === 'ascend') {
                ordering = field
            } else {
                ordering = '-' + field
            }
        }
        // 根据排序条件调用后端接口
        axios.get(URLUserTask, {
            params: {
                // 添加排序参数到请求中
                ordering: ordering,
                page: this.state.currentPage,
                page_size: this.state.pageSize,
            },
        })
            .then((response) => {
                this.setState({
                    data: response.data.results,
                    total: response.data.count,
                    // 更新排序状态，以便图标正确显示
                    sortedInfo,
                });
            })
            .catch((error) => {
                console.error("Error fetching sorted data:", error);
                message.error("获取排序数据时出错");
            });
    };

    handleChangeFamily = (e: { target: { value: any; }; }) => {
        this.setState({
            family: e
        })
    }

    handleChangeTag = (e: { target: { value: any; }; }) => {
        this.setState({
            tag: e
        })
    }

    handleClick = (e) => {
        this.setState({
            current: e.key,
        });
    };

    fetchUserInfo = async () => {
        const decodedToken = decodeToken(sessionStorage.getItem('ACCESS_TOKEN'));
        console.log('解码', decodedToken)
        this.setState({
            userId: decodedToken.user_id,
        }, async () => {
            const response = await axios.get(URLUserInfo(this.state.userId))
            const { data } = response
            this.setState({
                username: data.username,
                email: data.email,
                description: data.description,
                answer: data.answer,
                question: data.question,
                phone: data.phone,
            })
        })
    }

    onUserInfoFinish = (values: any) => {
        console.log('Received values of onUserInfoFinish: ', values);
    };

    onClickUploadLink = () => {
        go('/upload')
    };

    handleEmail = (e: { target: { value: any; }; }) => {
        this.setState({
            newEmail: e.target.value
        })
    }

    handleChangePhone = (e: { target: { value: any; }; }) => {
        this.setState({
            newPhone: e.target.value
        })
    }

    sendCaptcha = () => {
        this.formRef.current!.validateFields(['email']).then((values) => {
            if (this.state.canSend) {
                const data = {
                    username: this.state.username,
                    email: values.email,
                }
                axios.post(URLUserEmail, data)
                    .then(response => {
                        if (response.status === 201) {
                            message.success('验证码已发送至邮箱')
                        }
                        this.setState({ canSend: false, countdown: 300 }, () => {
                            this.countdownTimer = setInterval(() => {
                                const { countdown } = this.state;
                                if (countdown <= 0) {
                                    clearInterval(this.countdownTimer);
                                    this.setState({ countdown: 0, canSend: true });
                                } else {
                                    this.setState({ countdown: countdown - 1 });
                                }
                            }, 1000);
                        });
                    }).catch(e => {
                        // console.log('eeeeee', e.response)
                        message.error(e.response.data.message)
                    })
            }
        }).catch(e => {
            console.log('catch err就不会发送chunk请求')
        })
    };

    showModal = () => {
        this.setState({ visible: true }, () => {
            this.formRef.current!.resetFields()
        });
    };

    showChangePhoneModal = () => {
        this.setState({ changePhoneVisible: true });
    };

    onUpdateEmail = async () => {
        await axios.patch(URLUserInfo(this.state.userId), {
            email: this.state.newEmail,
            captcha: this.formRef.current!.getFieldValue('captcha'),
        })
        message.success('更换邮箱成功')
        this.setState({ visible: false })
        this.fetchUserInfo()
    }

    onUpdatePhone = async () => {
        await axios.patch(URLUserInfo(this.state.userId), {
            phone: this.state.newPhone,
        })
        message.success('更换绑定手机成功')
        this.setState({ changePhoneVisible: false })
        this.fetchUserInfo()
    }

    showChangePasswordModal = () => {
        this.setState({ changePasswordVisible: true });
    };

    handlePasswordChange = async (values) => {
        // 处理修改密码成功后的逻辑
        this.setState({ changePasswordVisible: false });
        this.props.history.push('/login'); // 跳转到登录页面
    };

    handleChangeScoreStart = (e: { target: { value: any; }; }) => {
        this.setState({
            scoreStart: e.target.value
        })
    }

    handleChangeScoreEnd = (e: { target: { value: any; }; }) => {
        this.setState({
            scoreEnd: e.target.value
        })
    }

    render() {
        const { current, username, email, description, answer, question,
            phone, data, visible, canSend, countdown, newEmail, changePhoneVisible, newPhone,
            total } = this.state;

        const columns = [
            {
                title: 'SHA256',
                dataIndex: 'sha256',
                key: 'sha256',
                width: 230,
                ellipsis: true,
            },
            {
                title: '提交时间',
                dataIndex: 'created_on',
                key: 'created_on',
                render: (value) => formatDate(value),
                sorter: true,
            },
            {
                title: '分析状态',
                dataIndex: 'state',
                key: 'state',
                render: (text) => (
                    text === 'finished' ? <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}><CheckCircleOutlined style={{ marginRight: '4px' }} />已完成</div> : <div style={{ color: 'rgba(82, 196, 26, 1)' }}><SearchOutlined style={{ marginRight: '4px' }} />正在分析</div>
                )
            },
            {
                title: '恶意分数',
                dataIndex: 'score',
                key: 'score',
            },
            {
                title: '操作',
                key: 'actions',
                render: (text, record) => (
                    <Space>
                        <Button type="primary" size='small' onClick={() => this.handleViewDetails(record.id)}>
                            查看
                        </Button>
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => this.handleDelete(record.id)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button type="danger" size='small'>删除</Button>
                        </Popconfirm>
                    </Space>
                ),
            },
        ];

        const isPhoneNumberValid = (rule: any, value: string, callback: any) => {
            if (!value) {
                return callback();
            }

            // 中国手机号正则表达式
            const phoneRegex = /^1[3-9]\d{9}$/;
            if (phoneRegex.test(value)) {
                callback();
            } else {
                callback(new Error('请输入正确的手机号'));
            }
        };

        return (
            <div className='task_wrap'>
                <Row gutter={30}>
                    <Col flex="270px">
                        <div className='user_info'>
                            <div>
                                <Avatar size={64} icon={<UserOutlined />} />
                            </div>
                            <Title level={3}>{username}</Title>
                            <Text type="secondary" style={{ marginBottom: '10px' }}>{email}</Text>
                            {/* <Tag icon={<CrownOutlined />} color="gold">
                                Twitter
                            </Tag> */}
                        </div>
                        <div className='user_upload'>
                            <Text>已上传样本</Text>
                            <Title level={3} style={{ margin: '8px 0 20px' }}>{total}个</Title>
                            <Button type="" onClick={this.onClickUploadLink}>
                                上传样本
                            </Button>
                        </div>
                        <div className='user_tab'>
                            <Menu
                                onClick={this.handleClick}
                                selectedKeys={[current]}
                                mode="inline"
                            >
                                <Menu.Item key="submitHistory" style={{ textAlign: 'center' }}>
                                    提交历史
                                </Menu.Item>
                                <Menu.Item key="accountSettings" style={{ textAlign: 'center' }}>
                                    账户设置
                                </Menu.Item>
                            </Menu>
                        </div>
                    </Col>
                    <Col flex="1">
                        <div className='task_table'>
                            {current === 'submitHistory' ? (<div>
                                <div className='result_title' style={{ alignItems: 'center' }}>
                                    <img src={surveyFillIcon} alt="" />
                                    <div>提交历史</div>
                                    <span>({total})</span>
                                </div>
                                <Form layout="inline" onFinish={this.handleSearch} style={{ marginBottom: '24px' }}>
                                    <Form.Item label="" name="createdOn">
                                        <RangePicker allowClear showTime
                                            format="YYYY/MM/DD HH:mm:ss" style={{ width: '240px' }} />
                                    </Form.Item>
                                    <Form.Item label="" name="score">
                                        <Input.Group compact>
                                            <Input style={{ width: 90, textAlign: 'center' }} placeholder="最低分" onChange={this.handleChangeScoreStart} />
                                            <Input
                                                style={{
                                                    width: 30,
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    pointerEvents: 'none',
                                                    backgroundColor: 'white',
                                                }}
                                                placeholder="~"
                                                disabled
                                            />
                                            <Input
                                                style={{
                                                    width: 90,
                                                    textAlign: 'center',
                                                    borderLeft: 'none',
                                                }}
                                                placeholder="最高分"
                                                onChange={this.handleChangeScoreEnd}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                    {/* <Form.Item label="" name="family">
                                        <Select allowClear placeholder="请选择家族" onChange={this.handleChangeFamily} showSearch filterOption={true} style={{ width: 200 }}>
                                            {this.state.families.map((family, index) => (
                                                <Option key={family}>{family}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="" name="tag">
                                        <Select allowClear placeholder="请选择标签" onChange={this.handleChangeTag}>
                                            {this.state.tags.map((tag, index) => (
                                                <Option key={tag}>{tag}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}
                                    <Form.Item>
                                        <Button type="" htmlType="submit">
                                            查询
                                        </Button>
                                    </Form.Item>
                                </Form>
                                <Table
                                    onChange={this.handleSorterChange}
                                    rowKey="id"
                                    dataSource={this.state.data}
                                    columns={columns}
                                    pagination={{
                                        total: this.state.total,
                                        current: this.state.currentPage,
                                        pageSize: this.state.pageSize,
                                        onChange: this.handleTableChange,
                                    }}
                                    loading={this.state.loading}
                                />
                            </div>) : (<div style={{ width: '450px' }}>
                                {/* <div className='user_form'>
                                    <div className='user_form_title'>
                                        <img src={contactsBookFillIcon} alt="" />
                                        <div>基本信息</div>
                                    </div>
                                    <Form layout="vertical" style={{ marginBottom: '24px' }} initialValues={{ username }} onFinish={this.onUserInfoFinish}
                                    >
                                        <Form.Item label="用户名：" name="username">
                                            <Input placeholder="用户名" />
                                        </Form.Item>
                                        <Form.Item label="性别：" name="sex">
                                            <Radio.Group>
                                                <Radio value="男"> 男 </Radio>
                                                <Radio value="女"> 女 </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item label="现居：" name="">
                                            <Select allowClear placeholder="请选择地区" onChange={this.handleChangeFamily}>
                                                {this.state.families.map((family, index) => (
                                                    <Option key={family}>{family}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item>
                                            <Row justify="end" align="middle">
                                                <Button type="primary" htmlType="submit">保存</Button>
                                            </Row>
                                        </Form.Item>
                                    </Form>
                                </div> */}

                                {/* <Divider /> */}

                                <div className='user_form'>
                                    <div className='user_form_title'>
                                        <img src={lockFillIcon} alt="" />
                                        <div>账户设置</div>
                                    </div>
                                    <Form layout="vertical" style={{ marginBottom: '24px' }}>
                                        <Form.Item label="登录密码：" name="">
                                            <Row justify="space-between" align="middle">
                                                <Col>
                                                    <Text type="secondary">建议您定期修改密码，以保证账号安全</Text>
                                                </Col>
                                                <Col>
                                                    <Button type="" htmlType="submit" onClick={this.showChangePasswordModal}>修改</Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Form.Item label="绑定邮箱：" name="">
                                            <Row justify="space-between" align="middle">
                                                <Col>
                                                    <Text type="">{email}</Text>
                                                </Col>
                                                <Col>
                                                    <Button danger onClick={this.showModal}>更换</Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Form.Item label="绑定手机号：" name="">
                                            <Row justify="space-between" align="middle">
                                                <Col>
                                                    <Text type={phone ? 'default' : 'secondary'}>{phone || '未绑定'}</Text>
                                                </Col>
                                                <Col>
                                                    <Button type="primary" htmlType="submit" onClick={this.showChangePhoneModal}>绑定</Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>)}

                        </div></Col>
                </Row>

                <Modal
                    title="更换邮箱"
                    visible={visible}
                    onOk={this.onUpdateEmail}
                    onCancel={() => this.setState({ visible: false })}
                >
                    <Form
                        layout="vertical"
                        ref={this.formRef}
                        name="register"
                    >
                        <Form.Item
                            label="新邮箱"
                            name="email"
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入新邮箱',
                                },
                                {
                                    type: 'email',
                                    message: '请输入正确的邮箱格式',
                                },
                            ]}
                        >
                            <Input
                                onChange={this.handleEmail}
                                value={newEmail}
                            />
                        </Form.Item>

                        <Form.Item
                            label="验证码"
                            name="captcha"
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入验证码',
                                },
                            ]}
                        >
                            <Row gutter={10}>
                                <Col flex="auto">
                                    <Input />
                                </Col>
                                <Col>
                                    <Button onClick={this.sendCaptcha} disabled={!canSend}>
                                        {countdown > 0 ? `${countdown}秒后 重新获取` : '获取验证码'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="更换绑定手机"
                    visible={changePhoneVisible}
                    onOk={this.onUpdatePhone}
                    onCancel={() => this.setState({ changePhoneVisible: false })}
                >
                    <Form layout="vertical" name="">
                        <Form.Item
                            label="手机号"
                            name="phone"
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    validator: isPhoneNumberValid,
                                    message: '请输入正确的手机号',
                                },
                            ]}
                        >
                            <Input onChange={this.handleChangePhone} />
                        </Form.Item>
                    </Form>
                </Modal>

                <ChangePasswordModal
                    visible={this.state.changePasswordVisible}
                    onOk={this.handlePasswordChange}
                    onCancel={() => this.setState({ changePasswordVisible: false })}
                    username={this.state.username}
                    userId={this.state.userId}
                    email={this.state.email}
                />

            </div>
        );
    }
}

export default withRouter(TaskCenter);
import React, { useState, useEffect } from 'react';
import { Table, Button, message, Modal, Form, Input, Select, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import axios from '../api/index';
import { URLGetUserRoles, URLRolePermissions, URLUserRoles } from '../api/config';

interface Role {
    id: string;
    name: string;
    description: string;
    permissions: string[];
}

interface Permission {
    name: string;
    label: string;
}

const RoleManagement: React.FC = () => {
    const [form] = Form.useForm();
    const [roles, setRoles] = useState<Role[]>([]);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [editingRole, setEditingRole] = useState<Role | null>(null);
    // const [rowData, setRowData] = useState<Role | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        fetchRoles();
        fetchPermissions();
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await axios.get(URLGetUserRoles);
            if (response.status === 200) {
                setRoles(response.data.results);
            }
        } catch (error) {
            console.error('Failed to fetch roles:', error);
            message.error('Failed to fetch roles. Please try again.');
        }
    };

    const fetchPermissions = async () => {
        try {
            const response = await axios.get(URLRolePermissions);
            if (response.status === 200) {
                setPermissions(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch permissions:', error);
            message.error('Failed to fetch permissions. Please try again.');
        }
    };

    const handleEdit = (record: Role) => {
        setEditingRole(record);
        // setRowData(record);
        setIsModalVisible(true);
    };

    const handleDelete = async (record: Role) => {
        try {
            await axios.delete(URLUserRoles(record.id));
            setRoles(roles.filter((role) => role.id !== record.id));
            message.success('删除成功');
        } catch (error) {
            console.error('Failed to delete role:', error);
            message.error('Failed to delete role. Please try again.');
        }
    };

    const handleSave = async (values: any) => {
        try {
            const updatedRole = {
                ...editingRole!,
                ...values,
            };
            await axios.patch(URLUserRoles(editingRole!.id), updatedRole);
            setRoles(roles.map((role) => (role.id === editingRole!.id ? updatedRole : role)));
            setIsModalVisible(false);
            message.success('修改成功');
        } catch (error) {
            console.error('Failed to update role:', error);
            message.error('Failed to update role. Please try again.');
        }
    };

    // useEffect(() => {
    //     if (!isModalVisible) {
    //         form.resetFields();
    //     } else {
    //         setEditingRole(rowData);
    //     }

    //     return () => {
    //         // setEditingRole(rowData);
    //     };
    // }, [isModalVisible, form, rowData]);

    const columns: ColumnsType<Role> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '角色名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '权限',
            dataIndex: 'permissions',
            key: 'permissions',
            render: (permissions: string[]) => permissions.join(', '),
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record: Role) => (
                <Space>
                    <Button onClick={() => handleEdit(record)}>修改</Button>
                    <Button onClick={() => handleDelete(record)}>删除</Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Table columns={columns} dataSource={roles} rowKey={'id'} />
            <Modal
                title="编辑角色"
                visible={isModalVisible}
                onOk={() => form.submit()}
                onCancel={() => setIsModalVisible(false)}
                okText="保存"
                cancelText="取消"
                destroyOnClose={true}
            >
                <Form
                    name="editRoleForm"
                    onFinish={handleSave}
                    preserve={false}
                    initialValues={{
                        name: editingRole?.name || '',
                        description: editingRole?.description || '',
                        permissions: editingRole?.permissions || [],
                    }}
                >
                    <Form.Item name="name" label="角色名称">
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="描述">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="permissions" label="权限">
                        <Select mode="multiple">
                            {permissions.map((perm) => (
                                <Select.Option key={perm.name} value={perm.name}>
                                    {perm.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default RoleManagement;
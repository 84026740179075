import { Component } from 'react';
import axios from './api/index'

import { message, Spin, Anchor } from 'antd';
import { ICS } from './Modules/ICS';
import { Summary } from './Modules/Summary';
import { ProcessDetail } from './Modules/ProcessDetail';
import { Screenshot } from './Modules/Screenshot';
import { Network } from './Modules/Network';
import { PEdata } from './Modules/PEdata';
import { DroppedFile } from "./Modules/DroppedFile";
import { Registry } from "./Modules/Registry";
import { VirusDetection } from "./Modules/VirusDetection";
import FeatureDisplay from "./Modules/FeatureDisplay";
import { MultiEngine } from "./Modules/MultiEngine";
import ProcessTree from "./Modules/ProcessTree";
import { StaticAnalyse } from "./Modules/StaticAnalyse";
import { DynamicAnalyse } from "./Modules/DynamicAnalyse";
import { platform } from 'os';
import { URLAnalysesPre, URLAnalysesPost } from './api/config'
import { ProfileOutlined, CodeSandboxOutlined, FolderViewOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';
const { Link } = Anchor;

export default class Detail extends Component<any, any> {

    state: any = {
        data: {
            '_id': "",
            'id': "",
            'behavior': {},
            'debug': {},
            'dropped': [],
            'extracted': [],
            'info': {},
            'metadata': {},
            'network': {},
            'screenshots': [],
            'signatures': [],
            'target': {
                filename: '',
                size: '',
                md5: '',
                sha1: '',
                sha256: '',
            },
            // 概览
            submitted: {},
            created_on: {},
            category: {},
            settings: {
                platforms: []
            },
            score: '',
            tasks: []
        },
        postResponse: {
            signatures: [],
            processes: {},
            screenshot: [],
            task_id: '',
        },
        preResponse: {
            static: {},
            virustotal: {},
            screenshot: [],
            task_id: '',
        },
        loading: true
    }
    async componentDidMount() {
        console.log('this.props.location', this.props.location)
        const { pathname } = this.props.location

        let id = pathname.split('/detail/')[1];
        if (id === undefined) {
            message.error("未获取到id");
            return;
        }
        const overviewData = await this.getOverview(id)
        // console.log('overviewData', overviewData)
        this.getPreDetail(id)
    }

    getOverview = async (id: number) => {
        const response = await axios.get(`/analysis/${id}`)
        if (response.status === 200) {
            const { data } = response
            console.log('response', response)
            this.setState({ data: data, loading: false }, () => {
                // console.log('setState callback', response, data)
                // this.getPreDetail(id)
                if (Array.isArray(data.tasks) && data.tasks[0]) {
                    this.getPostDetail(id, data.tasks[0].id)
                }

            })
            return Promise.resolve(data)
        } else {
            message.warning("分析中");
            console.error(response);
            return Promise.reject()
        }

    }
    getPostDetail = async (id: number, taskId: number) => {
        axios.get(URLAnalysesPost(id, taskId)).then((postResponse) => {
            // console.log('postResponse', postResponse)
            this.setState({ postResponse: postResponse }, () => {

            })
        })
    }
    getPreDetail = async (id: number) => {
        axios.get(URLAnalysesPre(id)).then((preResponse) => {
            console.log('getPreDetail', preResponse)
            this.setState({ preResponse: preResponse }, () => {

            })
        })
    }

    render() {
        const { loading, data, postResponse, preResponse } = this.state;
        // console.log(typeof data, 'signatures', postResponse.data?.signatures)
        // const {_id, behavior, info, network, screenshots, dropped, target} = data
        const { id } = data
        const info = {
            platform: '',
            machine: {
                label: '',
            },
            started: '',
            ended: '',
            id: '',
            score: '',
            added: '',
            sha256: '',
        }
        let target = {
            ...data.target,
            file: {
                name: data.target.filename,
                size: data.target.size,
                md5: data.target.md5,
                sha1: data.target.sha1,
                sha256: data.target.sha256,
                category: 'file',
                url: '',
            },
        }
        // console.log('data', data.category)
        let submitted = {
            ...data.submitted,
            created_on: data.created_on,
            category: data.category,
            platforms: data.settings.platforms,
            score: data.score,
            state: data.state,
        }
        let tasks = {
            ...data.tasks,
        }

        return loading ? <Spin /> :
            <div>
                <div className="row">
                    <NavBar />
                    <div className="col-lg-9 ml-lg-auto col-lg-10 px-lg-4">
                        <div id="summary">
                            <Summary target={target} info={info} _id={id} submitted={submitted} tasks={tasks} />
                        </div>

                        {/* 多引擎检测 */}
                        {/* <div id="engine">
                            <MultiEngine data={preResponse.data?.virustotal} />
                        </div> */}

                        <div id="pe">
                            {/*pe 信息*/}
                            <PEdata id="" static={preResponse.data?.static} />
                        </div>

                        <div id="static_analyse">
                            {/*静态分析*/}
                            <StaticAnalyse signatures={preResponse.data?.signatures} target={preResponse.data?.target} />
                        </div>

                        <div id="dynamic_analyse">
                            {/*动态分析*/}
                            <DynamicAnalyse signatures={postResponse.data?.signatures} processes={postResponse.data?.processes} screenshot={postResponse.data?.screenshot} id={id} task_id={postResponse.data?.task_id} network={postResponse.data?.network} />
                        </div>
                    </div>
                </div>
            </div>
    }
}


class NavBar extends Component<any, any> {
    scrollToAnchor(anchorName: string) {
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) {
                anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
        }
    }

    handleClick(event) {
        event.preventDefault();
    }

    render() {
        return (
            <nav className="col-md-3 col-lg-2 d-md-block sidebar">
                <Anchor onClick={this.handleClick}>
                    <Link href="#summary" title={<span><ProfileOutlined /> 基础信息</span>} />
                    <Link href="#pe" title={<span><CodeSandboxOutlined /> PE信息</span>} >
                        <Link href="#pe-import" title="导入表" className='anchor_padding' />
                        <Link href="#pe-export" title="导出表" className='anchor_padding' />
                        <Link href="#pe-section" title="PE节区" className='anchor_padding' />
                        <Link href="#pe-resource" title="PE资源" className='anchor_padding' />
                    </Link>
                    <Link href="#static_analyse" title={<span><FolderViewOutlined /> 静态分析</span>} className="anchor_fix">
                        <Link href="#static-feature" title="静态特征" className='anchor_padding' />
                    </Link>
                    <Link href="#dynamic_analyse" title={<span><FundProjectionScreenOutlined /> 动态分析</span>} className="anchor_fix">
                        <Link href="#dynamic-feature" title="动态特征" className='anchor_padding' />
                        <Link href="#dynamic-process-behavior" title="进程行为" className='anchor_padding' />
                        <Link href="#dynamic-network-behavior" title="网络行为" className='anchor_padding' />
                        <Link href="#dynamic-screenshot" title="运行截图" className='anchor_padding' />
                    </Link>
                </Anchor>
            </nav>
        )
    }
}


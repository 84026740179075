import React, { useState, useEffect } from 'react';
import { Table, Button, message, Space } from 'antd';
import axios from '../api/index';
import { URLDeleteTaskBatch, URLReanalysisBan, URLAnalyses, URLReanalysisBatch, URLReanalysisUnban } from '../api/config';
import { formatDate } from '../utils/index';


const TaskManage = () => {
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(URLAnalyses); // 假设这是你的API路径
            setData(response.data.results);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDeleteBatch = async () => {
        try {
            await axios.post(URLDeleteTaskBatch, { analysis_ids: selectedRowKeys });
            message.success('批量删除成功');
            fetchData(); // 刷新数据
        } catch (error) {
            console.error('Error deleting batch:', error);
            message.error('批量删除失败');
        }
    };

    const handleReanalyzeBatch = async () => {
        try {
            await axios.post(URLReanalysisBatch, { analysis_ids: selectedRowKeys });
            message.success('批量重新分析成功');
        } catch (error) {
            message.error(error.response.data.message || '批量重新分析失败');
        }
    };

    const handleBanReanalysis = async () => {
        try {
            await axios.post(URLReanalysisBan, { analysis_ids: selectedRowKeys });
            message.success('禁止重新分析成功');
        } catch (error) {
            console.error('Error banning reanalysis:', error);
            message.error('禁止重新分析失败');
        }
    };

    const handleUnbanReanalysis = async () => {
        try {
            await axios.post(URLReanalysisUnban, { analysis_ids: selectedRowKeys });
            message.success('允许重新分析成功');
        } catch (error) {
            console.error('Error unbanning reanalysis:', error);
            message.error('允许重新分析失败');
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'target',
            dataIndex: 'target',
            render: target => target.target, // 只显示target字段
        },
        {
            title: 'MD5',
            dataIndex: 'target',
            render: target => target.md5, // 只显示md5
        },
        {
            title: '状态',
            dataIndex: 'state',
        },
        {
            title: '提交时间',
            dataIndex: 'created_on',
            key: 'created_on',
            render: (value) => formatDate(value),
        },
        {
            title: '恶意分数',
            dataIndex: 'score',
            key: 'score',
        },
    ];

    return (
        <div style={{ padding: '24px' }}>
            <Space>
                <Button type="primary" onClick={handleDeleteBatch}>批量删除</Button>
                <Button type="primary" onClick={handleReanalyzeBatch}>批量重新分析</Button>
                <Button type="primary" onClick={handleBanReanalysis}>禁止重新分析</Button>
                <Button type="primary" onClick={handleUnbanReanalysis}>允许重新分析</Button>
            </Space>
            <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data.map(item => ({
                    ...item,
                    key: item.id,
                }))}
            />
        </div>
    );
};

export default TaskManage;
import React, {Component} from 'react';
import {Upload, message, Radio} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import axios from "axios";
// @ts-ignore
import ReactCanvasNest from 'react-canvas-nest';


const {Dragger} = Upload;
export default class Home extends Component<any, any> {
    state = {
        machines: [],
        selectedMachine: 'default',
        count:[],
        userIp:[]
    }

    static props = {
        name: 'file',
        multiple: false,
        action: process.env.REACT_APP_API_ENDPOINT + 'push/file',
        // showUploadList: false,
        beforeUpload: (file: any) => {
            if (file.size > 102400000) {
                message.error("上传文件不得超过100M");
                return false
            }
            return true
        },
        onChange(info: { file: { name?: any; status?: any; }; fileList: any; }) {
            const {status} = info.file;
            if (status === 'done') {
                const hide = message.success(`${info.file.name} 文件上传成功，请稍后.`, 0);
                setTimeout(function () {
                    hide();
                    message.success("已加入队列")
                    window.location.href = "/#/pending";
                }, 1500);
            } else if (status === 'error') {
                const hide = message.loading(`${info.file.name} 文件上传错误，请将错误信息告知管理员`, 0);
                setTimeout(hide, 2500);
            }
        },
    };

    componentWillMount() {
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'main/machines').then(response => {
            if (response.status === 200) {
                const {data} = response;
                this.setState({machines: data})
            } else {
                message.error("从服务器获取信息失败.")
                console.error(response)
            }
        })

        axios.get(process.env.REACT_APP_API_ENDPOINT + 'main/count').then(response => {
            if (response.status === 200) {
                const {data} = response;
                this.setState({count: data})
            } else {
                message.error("从服务器获取信息失败.")
                console.error(response)
            }
        })
    }

    uploadFile() {
        let el = document.getElementById("upload__input");
        if (el === null) return;
        el.click()
    }
    userIP(){
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'main/userIp').then(response => {
            if (response.status === 200) {
                const {data} = response;
                this.setState({userIp: data})
                console.log(data)
            } else {
                message.error("从服务器获取信息失败.")
                console.error(response)
            }
        })
    }

    render() {
        const {machines, selectedMachine,count} = this.state;
        return (
            <main className="main" style={{marginTop: "-64px"}}>
                <ReactCanvasNest className='canvasNest' config={{
                    pointColor: ' 255, 255, 255 ',
                    lineColor: ' 255, 255, 255 ',
                    dist: '26000',
                    pointR: '2.5'
                }} style={{zIndex: 0}}/>
                <img className="bg" src="assets/images/bg1.png" alt=""/>
                <div className="wrapper">
                    <img src="assets/images/logo-small.png" alt="" className="logo-small"/>
                    <div className="name">工控病毒沙箱</div>
                    <div className="english-name">GUOLI SECURITY CLOUD SANDBOX</div>
                    <div style={{marginBottom: "100px"}}>
                        <div className="tips">
                            <i className="iconfont icon-sanjiaojinggao"/>
                            <span>选择运行机器</span>
                        </div>
                        <div className="nav-search flex-between">
                            <Radio.Group defaultValue="default" buttonStyle="solid">
                                <Radio.Button value="default" key="default">默认机器</Radio.Button>
                                {machines.map((machine: any) => {
                                    return <Radio.Button
                                        onChange={e => this.setState({selectedMachine: e.target.value})}
                                        value={machine.id}
                                        key={machine.id}>{machine.name}</Radio.Button>
                                })}
                            </Radio.Group>
                        </div>
                        <div style={{height: "20px"}}/>
                        <Dragger {...Home.props} data={{"selectedMachine": selectedMachine}} style={{opacity: "90%"}}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">点击或拖拽文件到此处上传</p>
                            <p className="ant-upload-hint">
                                文件上传后会自动开始分析
                                <br/>
                                支持文件: exe, png, jpg等
                            </p>
                        </Dragger>

                    </div>
                    <footer className='footer2'>已上传样本数 {count}</footer>
                    <footer className='footer'>版权所有© 2021 浙江国利网安科技有限公司</footer>
                    <footer className='footer1'><a href="https://beian.miit.gov.cn/">浙ICP备19009015号</a></footer>

                </div>

            </main>


        );
    }
}

// 通过邮箱获取验证码
export const URLUserEmail = '/user/email'

// 用户注册
export const URLUserRegister = '/user/register'

// 登录
export const URLUserLogin = '/user/login'

// 获取全部的tag和family
export const URLTagsFamilies = '/targets/tags-families/'

// 样本列表
export const URLTargets = '/targets/'

// 获取分析总览
export const URLAnalyses = '/analyses/'

// 用户个人任务
export const URLUserTask = '/user/task'

// 删除分析记录
export const URLDeleteAnalysis = (analysis_id) => `/analysis/${analysis_id}/deleteanalysis`

// 某项分析任务的详情
export const URLAnalysisDetail = (analysis_id) => `/analysis/${analysis_id}`

// 下载分析的目标文件
export const URLSubmittedFile = (analysis_id) => `/analysis/${analysis_id}/submittedfile`

// 下载流量包
export const URLPcapFile = (analysis_id, task_id) => `/analysis/${analysis_id}/task/${task_id}/pcap`

// 静态分析结果
export const URLAnalysesPre = (analysis_id) => `/analysis/${analysis_id}/pre`

// 动态分析结果
export const URLAnalysesPost = (analysis_id, task_id) => `/analysis/${analysis_id}/task/${task_id}/post`

// 下载截图
export const URLScreenshot = (analysis_id, task_id, screenshot) => `/analysis/${analysis_id}/task/${task_id}/screenshot/${screenshot}`

// 资讯列表
export const URLSecurityInfos = `/security/infos/`

// 上传样本
export const URLSubmitFile = `/submit/file/`

// 重新分析
export const URLReanalysis = (analysis_id) => `/analysis/${analysis_id}/reanalysis/`

// 分析任务基础信息统计
export const URLAnalysisStatic = '/analysis/static/'

// 靶场选项
export const URLSubmitPlatforms = '/submit/platforms/'

// 开始分析
export const URLStartAnalysis = (analysis_id) => `/submit/start/${analysis_id}/`

// 用户详细信息与更新
export const URLUserInfo = (id) => `/user/users/${id}/`

// 角色管理
export const URLUserRoles = (id) => `/user/roles/${id}/`

// 批量删除分析任务，返回204状态码
export const URLDeleteTaskBatch = `/analysis/delete/batch/`

// 分析任务禁止重新分析
export const URLReanalysisBan = `/analysis/reanalysis/ban/`

// 批量重新分析，使用post接口，状态码201
export const URLReanalysisBatch = `/analysis/reanalysis/batch/`

// 分析任务允许重新分析
export const URLReanalysisUnban = `/analysis/reanalysis/unban/`

// 获取全部权限
export const URLRolePermissions = `/user/roles/permissions/`

// 角色管理
export const URLGetUserRoles = `/user/roles/`

// 忘记密码
export const URLUserForget = `/user/forget/`

// 忘记密码前的邮箱验证
export const URLUserVerify = `/user/verify/`

// @ts-nocheck
import { Form, Input, Button, message, Row, Col, Space } from 'antd';
import 'antd/dist/antd.css';
import React from 'react'
import '../css/login.css'
import { URLUserEmail, URLUserRegister } from '../api/config'
import {
    UserOutlined,
    MailOutlined,
    CreditCardOutlined,
    SecurityScanOutlined, CodeSandboxOutlined
} from "@ant-design/icons";
import { encryptPassword, encryptPwd, decryptPassword } from '../utils/encrypt';
import axios from '../api/index'
import type { FormInstance } from 'antd/es/form';


export default class Register extends React.Component {
    formRef = React.createRef<FormInstance>();

    state = {
        confirmDirty: false,
        password: '',
        username: '',
        email: '',
        captcha: '',
        countdown: 0,
        canSend: true, // 是否可以发送验证码
    };
    // 实现注册功能
    submitH = (values) => {
        // if (this.state.password !== this.state.confirm) {
        //     return message.info("两次密码不一致")
        // }
        let data = {
            username: values.username,
            password: encryptPwd(values.password),
            email: values.email,
            captcha: values.captcha.trim()
        }
        console.log(data)
        axios.post(URLUserRegister, data, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                console.log('register response', response)
                if (response.status == 201) {
                    message.success('注册成功')
                    // @ts-ignore
                    this.props.history.push('/login')
                }
            }).catch(e => {
                message.error(e.response.data.message)
            })
    }

    handleSubmit = (e: any) => {
        console.log(this.state)
        e.preventDefault();
        // let error = false
        // @ts-ignore
        // this.props.form.validateFields((err: any, values: any) => {
        //     console.log('huxb')
        //     console.log(err)
        //     console.log(values)
        //     if (!err) {
        //         console.log('error' + error)
        //         console.log('Received values of form: ', values);
        //         return
        //     }
        // });
        this.formRef.current!.validateFields().then((values) => {
            this.submitH(values)
        }).catch(() => { })

    }
    handleEmail = (e: { target: { value: any; }; }) => {
        this.setState({
            email: e.target.value
        })
    }
    handlePassword = (e: { target: { value: any; }; }) => {
        this.setState({
            password: e.target.value
        })
    }
    handleUsername = (e: { target: { value: any; }; }) => {
        this.setState({
            username: e.target.value
        })
    }
    handleConfirm = (e: { target: { value: any; }; }) => {
        this.setState({
            confirm: e.target.value
        })
    }
    handleConfirmBlur = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }
    sendCaptcha = () => {
        this.formRef.current!.validateFields(['username', 'email']).then((values) => {
            if (this.state.canSend) {
                const data = {
                    username: values.username,
                    email: values.email,
                }
                axios.post(URLUserEmail, data)
                    .then(response => {
                        if (response.status === 201) {
                            message.success('验证码已发送至邮箱')
                        }
                        this.setState({ canSend: false, countdown: 300 }, () => {
                            this.countdownTimer = setInterval(() => {
                                const { countdown } = this.state;
                                if (countdown <= 0) {
                                    clearInterval(this.countdownTimer);
                                    this.setState({ countdown: 0, canSend: true });
                                } else {
                                    this.setState({ countdown: countdown - 1 });
                                }
                            }, 1000);
                        });
                    }).catch(e => {
                        // console.log('eeeeee', e.response)
                        message.error(e.response.data.message)
                    })
            }
        }).catch(e => {
            console.log('catch err就不会发送chunk请求')
        })

    };

    componentWillUnmount() {
        // 组件卸载时清除计时器
        clearInterval(this.countdownTimer);
    }

    render() {
        let getCodetext = '获取';
        const { username, password, email, countdown, canSend } = this.state
        const onFinish = (values: any) => {
            console.log('Received values of form: ', values);
        };

        return (

            <main className="mt-4 login_wrap">
                {/* <img className="bg" src='assets/images/register.png' />
                <img className="bg1" src='assets/images/loginkuang.png' /> */}
                <div className='login_title'>
                    <CodeSandboxOutlined className='login_title_icon' />
                    <div>
                        <div className='login_title_cn'>工控情报中心</div>
                        <div className='login_title_en'>ICS Threat Intelligence Center</div>
                    </div>
                </div>
                <div className='login-main'>
                    <div className='greet'>注册账号</div>
                    <Form
                        ref={this.formRef}
                        name="register"
                        className='register-form'
                        onFinish={onFinish}
                        layout='vertical'>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="用户名"
                                    name="username"
                                    rules={[{ required: true, message: '请输入用户名!' },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    },
                                    {
                                        min: 6,
                                        message: '用户名长度需要大于6',
                                    }]}
                                >
                                    <Input value={username}
                                        onChange={this.handleUsername}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="电子邮箱"
                                    name="email"
                                    validateTrigger={['onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入电子邮箱!',
                                        },
                                        {
                                            type: 'email',
                                            message: '请输入正确的邮箱格式!',
                                        },
                                    ]}
                                >
                                    <Input onChange={this.handleEmail}
                                        value={email} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="登录密码"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入密码!',
                                        },
                                        {
                                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                                            message: '设置格式为8-16个字符，请确保包含至少一个小写字母、一个大写字母、一个数字'
                                        }
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password onChange={this.handlePassword}
                                        className="password-input"
                                        value={password}
                                        placeholder='格式为8-16个字符，至少包含一个小写字母、一个大写字母、一个数字' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="验证码"
                                    name="captcha"
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入验证码',
                                        },
                                    ]}
                                >
                                    <Row gutter={10}>
                                        <Col flex="auto">
                                            <Input /></Col><Col>
                                            <Button onClick={this.sendCaptcha} disabled={!canSend} >
                                                {countdown > 0 ? `${countdown}秒后 重新获取` : '获取验证码'}
                                            </Button>
                                        </Col></Row>
                                </Form.Item>
                                {/* <CaptchaComponent username={this.state.username} email={this.state.email} ></CaptchaComponent> */}
                            </Col>
                            {/* <Col>
                                <Form.Item>
                                    <Button onClick={this.sendCaptcha} disabled={!canSend} type="primary">
                                        {countdown > 0 ? `${countdown}秒后 重新获取` : '获取验证码'}
                                    </Button>
                                </Form.Item>
                            </Col> */}
                        </Row>
                        <Form.Item>
                            <Button className='login-form-button' type="primary" htmlType="submit" onClick={this.handleSubmit}>
                                注册
                            </Button>
                        </Form.Item>
                    </Form>

                </div>
                <div>已经有账号了？<a href="/#/login">去登录</a></div>
            </main>
        );
    }
}


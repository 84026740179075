import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Button, Row, Col, message } from 'antd';
import axios from '../api/index';
import { URLUserInfo, URLUserEmail } from '../api/config';
import { encryptPwd } from '../utils/encrypt';

interface ChangePasswordModalProps {
    visible: boolean;
    onOk: (values: any) => void;
    onCancel: () => void;
    email: string;
    userId: string;
    username: string;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ visible, onOk, onCancel, email, userId, username }) => {
    const [form] = Form.useForm();
    const [canSend, setCanSend] = useState(true);
    const [countdown, setCountdown] = useState(0);
    const [countdownTimer, setCountdownTimer] = useState<NodeJS.Timeout | null>(null);
    const countdownRef = useRef(countdown);

    useEffect(() => {
        countdownRef.current = countdown;
      }, [countdown]);

    useEffect(() => {
        // 当模态框打开时，清空表单内容
        if (visible) {
            form.resetFields();
        }

        return () => {
            if (countdownTimer) {
                clearInterval(countdownTimer);
            }
        };
    }, [visible, form, countdownTimer]);

    const sendCaptcha = () => {
        if (canSend) {
            const data = {
                username: username,
                email: email,
            };

            axios.post(URLUserEmail, data)
                .then(response => {
                    if (response.status === 201) {
                        message.success('验证码已发送至邮箱');
                        setCanSend(false);
                        setCountdown(300);
                        const timer = setInterval(() => {
                            
                            if (countdownRef.current <= 0) {
                                clearInterval(timer);
                                setCanSend(true);
                                setCountdown(0);
                            } else {
                                setCountdown(countdownRef.current - 1);
                            }
                        }, 1000);
                        setCountdownTimer(timer);
                    }
                })
                .catch(e => {
                    message.error(e.response.data.message);
                });
        }
    };

    const handlePasswordChange = async (values) => {
        try {
            const { newPassword, confirmPassword, passwordCaptcha } = values;
            if (newPassword !== confirmPassword) {
                message.error('两次输入的新密码不一致');
                return;
            }

            const response = await axios.patch(URLUserInfo(userId), {
                password: encryptPwd(newPassword),
                captcha: passwordCaptcha,
            });

            if (response.status === 200) {
                message.success('密码修改成功，请重新登录');
                onOk(values);
            }
        } catch (error) {
            console.error('Error changing password:', error);
            message.error('密码修改失败，请重试');
        }
    };

    return (
        <Modal
            title="修改密码"
            visible={visible}
            onOk={() => form.submit()}
            onCancel={onCancel}
            width={540}
        >
            <Form
                form={form}
                layout="vertical"
                name="changePassword"
                onFinish={handlePasswordChange}
            >
                <Form.Item
                    label="新密码"
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: '请输入新密码',
                        },
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                            message: '设置格式为8-16个字符，请确保包含至少一个小写字母、一个大写字母、一个数字'
                        },
                    ]}
                >
                    <Input.Password placeholder='格式为8-16个字符，至少包含一个小写字母、一个大写字母、一个数字'/>
                </Form.Item>
                <Form.Item
                    label="确认新密码"
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请再次输入新密码',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的新密码不一致'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="验证码"
                    name="passwordCaptcha"
                    rules={[
                        {
                            required: true,
                            message: '请输入验证码',
                        },
                    ]}
                >
                    <Row gutter={10}>
                        <Col flex="auto">
                            <Input />
                        </Col>
                        <Col>
                            <Button onClick={sendCaptcha} disabled={!canSend}>
                                {countdown > 0 ? `${countdown}秒后 重新获取` : '获取验证码'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ChangePasswordModal;
// @ts-nocheck
import React from 'react';
import { Tree } from 'antd';

const { TreeNode } = Tree;

interface ProcessTreeProps {
  processes?: any; // 应该根据实际类型进行定义
}

interface ProcessTreeState {}

// 数据转换函数
function transformData(arr) {
  let tree = []
  const map = {}
  for (let item of arr) {
    // 一个新的带children的结构
    let newItem = (map[item.pid] = {
      children: [],
      key: `key_${item.pid}`,
      title: item.name,
      data: {
        ...item
      }
    })
    if (map[item.ppid]) {
      // 父节点已存进map则在父节点的children添加新元素
      let parent = map[item.ppid]
      parent.children.push(newItem)
    } else {
      // 没有父节点，在根节点添加父节点
      tree.push(newItem)
    }
  }
  return tree
}

// 自定义节点标题渲染
const CustomTitle = ({ node }) => {
  const { name, pid, commandline } = node.data;
  return (
    <div>
      <strong>{name}</strong> pid: ({pid})
      <div style={{ color: '#888', fontSize: '0.9em' }}>{commandline}</div>
    </div>
  );
};

class ProcessTree extends React.Component<ProcessTreeProps, ProcessTreeState> {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
    };
  }

  componentDidUpdate(prevProps) {
    // if (this.props.processes.process_list) {
    //   const treeData = transformData(this.props.processes.process_list);
    //   this.setState({ treeData });
    // }
    if (prevProps.processes?.process_list !== this.props.processes?.process_list) {
      // 如果发生变化，更新state
      const treeData = transformData(this.props.processes.process_list);
      this.setState({ treeData });
    }

  }

  render() {
    const { treeData } = this.state;

    return (
      <div>
        <div className="">
          {/* <div className="header">
            <span className="header-name">进程树</span>
            <span className="english-name">Process Tree</span>
          </div> */}
          <div className="open-content">
            <Tree
              defaultExpandedKeys={['0']} // 展开根节点
              treeData={treeData}
              showLine
              titleRender={node => <CustomTitle node={node} />}
            />
          </div>
        </div>
      </div>

    );
  }
}

export default ProcessTree;
// @ts-nocheck
import React from 'react';
import { Card, Collapse, List, Typography, Tag, Row, Col, Table, Space, Empty } from 'antd';
import { CaretRightOutlined, ExclamationCircleFilled } from '@ant-design/icons';

const { Panel } = Collapse;
const { Text } = Typography;

const colorMap = {
  高危: 'rgba(255, 241, 240, 1)', // 高危红色
  可疑: 'rgb(255 231 212)', // 可疑橙色
  通用: 'rgba(255, 251, 230, 1)', // 通用淡黄色
};

const fontColorMap = {
  高危: 'rgba(207, 19, 34, 1)', // 高危红色
  可疑: 'rgb(207 88 19)', // 可疑橙色
  通用: 'rgba(212, 136, 6, 1)', // 通用淡黄色
};

const fixedOrder = ['高危', '可疑', '通用'];

function getOrderedValues(categorizedSignatures: Record<string, any[]>, fixedOrder: string[]): [string, any[]][] {
  return fixedOrder.filter(category => category in categorizedSignatures).map(category => [category, categorizedSignatures[category]]);
}

class FeatureDisplay extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props', props)
    this.state = {
      signatures: props.signatures || [],
      iocsDisplayRange: {}, // 新增状态来跟踪每个signature的ioc显示范围
    };
  }

  componentDidUpdate(prevProps) {
    // 检查props.signatures是否发生了变化
    if (prevProps.signatures !== this.props.signatures) {
      // 如果发生变化，更新state
      this.setState({ signatures: this.props.signatures });
    }
  }

  renderIocItem = (obj) => {
    // console.log('ioc', obj)
    const ioc = obj.ioc
    return <List.Item key={Math.random().toString(36).slice(-8)}>
      {Object.entries(ioc).map(([key, value]) => (
        <div key={`${ioc}-${key}`}>
          <Text strong>{key}:</Text> {value}
          <br /> {/* 添加换行以便于阅读，或根据需要调整样式 */}
        </div>
      ))}
    </List.Item>
  };

  onLoadMore = (sigIndex) => {
    this.setState(prevState => {
      const newIocsDisplayRange = { ...prevState.iocsDisplayRange };
      newIocsDisplayRange[sigIndex] = {
        // 假设每次加载5个，可以根据需要调整
        start: (newIocsDisplayRange[sigIndex]?.start || 0) + 5,
      };
      return { iocsDisplayRange: newIocsDisplayRange };
    });
  };

  render() {
    const { signatures } = this.state;

    const categorizedSignatures = signatures.reduce((acc, sig) => {
      let category;
      if (sig.score === 10) category = '高危';
      else if (sig.score >= 7 && sig.score <= 9) category = '可疑';
      else category = '通用';
      if (!acc[category]) acc[category] = [];
      acc[category].push(sig);
      return acc;
    }, {});

    const columns = [
      {
        title: '描述',
        dataIndex: ['ioc', 'description'],
        key: 'description',
        width: 200,
        ellipsis: true,
      },
      {
        title: '值',
        key: 'value',
        dataIndex: ['ioc', 'value'],
        width: 450,
        ellipsis: true,
      },
      {
        title: '进程名称',
        dataIndex: ['ioc', 'process'],
        key: 'process',
        ellipsis: true,
        width: 200,
      },
      {
        title: '进程号',
        dataIndex: ['ioc', 'process_id'],
        key: 'process_id',
        width: 100,
      },
    ];

    const data = getOrderedValues(categorizedSignatures, fixedOrder)
    const length = data.length

    return (
      <>
        {length > 0 ? (
          <>
            {data.map(([category, sigs], index) => (
              <Card
                key={index}
                headStyle={{ backgroundColor: colorMap[category], marginBottom: 16, color: fontColorMap[category], fontWeight: 'bold', fontSize: 16, letterSpacing: 1 }}
                title={(<Space><ExclamationCircleFilled /><span>{category.charAt(0).toUpperCase() + category.slice(1)}特征</span><span style={{ letterSpacing: 0 }}>({sigs.length})</span></Space>)}
                bodyStyle={{ padding: 0 }}
                style={{ marginBottom: 33 }}
                bordered={false}
              >
                <Collapse accordion>
                  {sigs.map((sig, idx) => (
                    <Panel
                      header={
                        <div>
                          <Row justify="space-between" align="middle"> {/* justify控制水平对齐方式，align控制垂直对齐 */}
                            <Col span={10}> {/* 根据需要调整每列的span值 */}
                              <Text strong>{sig.description}</Text>
                            </Col>
                            <Col span={5}>
                              {sig.family && <Tag>{sig.family}</Tag>}
                            </Col>
                            <Col span={5}>
                              {sig.tags.length > 0 && <Tag>{sig.tags.join(', ')}</Tag>}
                            </Col>
                            <Col span={3} style={{ textAlign: 'right' }}> {/* 全宽列，文本右对齐 */}
                              得分: {sig.score} 分
                            </Col>
                          </Row>
                        </div>
                      }
                      key={idx}
                    >
                      {/* <div>{JSON.stringify(sig.iocs.iocs)}</div> */}
                      <Table
                        rowKey={(record) => `${record.ioc.process}_${Math.random()}`}
                        dataSource={sig.iocs.iocs}
                        columns={columns}
                      />
                      {/* <List
                    dataSource={sig.iocs.iocs.slice(0, this.state.iocsDisplayRange[idx]?.start || 5)}
                    loadMore={
                      sig.iocs.iocs.length > (this.state.iocsDisplayRange[idx]?.start || 5) ? (
                        <div style={{ textAlign: 'center', margin: '10px 0' }} onClick={() => this.onLoadMore(idx)}>
                          Load More
                        </div>
                      ) : null
                    }
                    renderItem={this.renderIocItem}
                  /> */}
                    </Panel>
                  ))}
                </Collapse>
              </Card>
            ))}
          </>
        ) : <Empty description={false}/>}
      </>
    );
  }
}

export default FeatureDisplay;
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Button, Row, Col, message } from 'antd';
import axios from '../api/index';
import { URLUserForget, URLUserEmail, URLUserVerify } from '../api/config';
import { encryptPwd } from '../utils/encrypt';

interface ForgetPasswordModalProps {
    visible: boolean;
    onOk: (values: any) => void;
    onCancel: () => void;
}

const ForgetPasswordModal: React.FC<ForgetPasswordModalProps> = ({ visible, onOk, onCancel }) => {
    const [form] = Form.useForm();
    const [canSend, setCanSend] = useState(true);
    const [valid, setValid] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [countdownTimer, setCountdownTimer] = useState<NodeJS.Timeout | null>(null);
    const countdownRef = useRef(countdown);

    useEffect(() => {
        countdownRef.current = countdown;
    }, [countdown]);

    useEffect(() => {
        // 当模态框打开时，清空表单内容
        if (visible) {
            form.resetFields();
        }

        return () => {
            if (countdownTimer) {
                clearInterval(countdownTimer);
            }
        };
    }, [visible, form, countdownTimer]);

    const sendCaptcha = () => {
        if (canSend) {
            const data = {
                username: username,
                email: email,
            };

            axios.post(URLUserEmail, data)
                .then(response => {
                    if (response.status === 201) {
                        message.success('验证码已发送至邮箱');
                        setCanSend(false);
                        setCountdown(300);
                        const timer = setInterval(() => {
                            if (countdownRef.current <= 0) {
                                clearInterval(timer);
                                setCanSend(true);
                                setCountdown(0);
                            } else {
                                setCountdown(countdownRef.current - 1);
                            }
                        }, 1000);
                        setCountdownTimer(timer);
                    }
                })
                .catch(e => {
                    message.error(e.response.data.message);
                });
        }
    };

    const handlePasswordChange = async (values) => {
        try {
            const { newPassword, captcha } = values;

            const response = await axios.post(URLUserForget, {
                password: encryptPwd(newPassword),
                captcha: captcha,
                email: email,
            });

            if (response.status === 201) {
                message.success('密码修改成功，请重新登录');
                onOk(values);
            }
        } catch (error) {
            message.error(error.response.data.message);
        }
    };

    const handleUsername = (e: { target: { value: any; }; }) => {
        setUsername(e.target.value)
    }

    const handleEmail = (e: { target: { value: any; }; }) => {
        setEmail(e.target.value)
    }

    const handleValid = async () => {
        await form.validateFields(['username', 'email'])
        try {
            const response = await axios.post(URLUserVerify, {
                username: username,
                email: email,
            });

            if (response.status === 200) {
                message.success('用户名、邮箱校验成功');
                setValid(true)
            }
        } catch (error) {
            message.error('用户名、邮箱校验失败，请重试');
            setValid(false)
        }
    }

    return (
        <Modal
            title="忘记密码"
            visible={visible}
            onOk={() => form.submit()}
            onCancel={onCancel}
            width={540}
            okButtonProps={{ disabled: !valid }}
        >
            <Form
                form={form}
                layout="vertical"
                name="changePassword"
                onFinish={handlePasswordChange}
            >
                {valid ? (<><Form.Item
                    label="验证码"
                    name="captcha"
                    rules={[
                        {
                            required: true,
                            message: '请输入验证码',
                        },
                    ]}
                >
                    <Row gutter={10}>
                        <Col flex="auto">
                            <Input placeholder='请输入注册账号邮箱收到的验证码' autoComplete='new-password' />
                        </Col>
                        <Col>
                            <Button onClick={sendCaptcha} disabled={!canSend}>
                                {countdown > 0 ? `${countdown}秒后 重新获取` : '获取验证码'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入新密码',
                            },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                                message: '设置格式为8-16个字符，请确保包含至少一个小写字母、一个大写字母、一个数字'
                            },
                        ]}
                    >
                        <Input.Password placeholder='格式为8-16个字符，至少包含一个小写字母、一个大写字母、一个数字' autoComplete='new-password' />
                    </Form.Item></>) : (<>
                        <Form.Item
                            label="用户名"
                            name="username"
                            rules={[{ required: true, message: '请输入用户名!' },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            },
                            {
                                min: 6,
                                message: '用户名长度需要大于6',
                            }]}
                        >
                            <Input onChange={handleUsername}
                            />
                        </Form.Item>
                        <Form.Item
                            label="电子邮箱"
                            name="email"
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入电子邮箱!',
                                },
                                {
                                    type: 'email',
                                    message: '请输入正确的邮箱格式!',
                                },
                            ]}
                        >
                            <Input onChange={handleEmail} />
                        </Form.Item>
                        <Form.Item>
                            <Button onClick={handleValid}>校验</Button>
                        </Form.Item></>)}
            </Form>
        </Modal >
    );
};

export default ForgetPasswordModal;
// @ts-nocheck
import { Component } from "react";
import { Table, Tabs, Tag, Timeline, Collapse, Empty, List, Divider, Col, Row, Typography } from "antd";
import ExpandableContent from './ExpandableContent'
import { isObject } from "util";
import peIcon from "../images/Icon/peicon.svg";
import { CodeSandboxOutlined, } from '@ant-design/icons';

const { Panel } = Collapse
const { TabPane } = Tabs;
const { Title } = Typography;

export class PEdata extends Component<any, any> {
    render() {
        const { static: staticData } = this.props
        // console.log('pe props', this.props)
        if (!staticData) {
            return <Empty description={false} />
        }
        if (Object.keys(staticData).length === 0) {
            return <Empty description={false} />
        }
        const { pe } = staticData

        // 导入表
        const peImports = pe.pe_imports
        const columns = [
            {
                title: 'DLL',
                dataIndex: 'dll',
                key: 'dll',
                width: 500,
            },
            {
                title: '函数数量',
                key: 'count',
                render: (record) => {
                    return record.imports.length
                }
            },
        ]
        const generateRowKey = (record) => {
            return `${record.dll}_key_${record.imports.length}_${record.imports[0] ? record.imports[0]?.name : 'none'}`;
        };
        const expandedRowRender = (record) => {
            const { imports } = record;

            // 为展开行的无边框表格定义列
            const importColumns = [
                {
                    title: '函数名',
                    dataIndex: 'name',
                    key: 'name',
                    width: 505,
                },
                {
                    title: '函数地址',
                    dataIndex: 'address',
                    key: 'address',
                },
            ];

            return (
                <ExpandableContent>
                    <Table
                        bordered={false} // 关闭边框，创建无边框表格
                        dataSource={imports}
                        columns={importColumns}
                        pagination={false} // 展开行的表格通常不需要分页
                        size="small" // 可选，设置表格大小
                        rowKey={(record) => record.name + record.address}
                    />
                </ExpandableContent>
            );
        };

        // 导出表
        const peExports = pe.pe_exports
        const exportsColumns = [
            {
                title: '函数名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '函数地址',
                dataIndex: 'address',
                key: 'address'
            },
            {
                title: '函数序号',
                dataIndex: 'ordinal',
                key: 'ordinal'
            },
        ]
        const generateExportsRowKey = (record) => {
            return `${record.address}_key_${record.name}`;
        };
        const expandedExportsRowRender = (record) => {
            const { imports } = record;

            // 为展开行的无边框表格定义列
            const importColumns = [
                {
                    title: '函数名',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '函数地址',
                    dataIndex: 'address',
                    key: 'address',
                },
            ];

            return (
                <Table
                    bordered={false} // 关闭边框，创建无边框表格
                    dataSource={imports}
                    columns={importColumns}
                    pagination={false} // 展开行的表格通常不需要分页
                    size="small" // 可选，设置表格大小
                    rowKey={(record) => record.name + record.address}
                />
            );
        };

        // 节区
        const peSections = pe.pe_sections
        const sectionColumns = [
            {
                title: '节名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '虚拟地址',
                dataIndex: 'virtual_address',
                key: 'virtual_address'
            },
            {
                title: '虚拟大小',
                dataIndex: 'virtual_size',
                key: 'virtual_size'
            },
            {
                title: '物理大小',
                dataIndex: 'size_of_data',
                key: 'size_of_data'
            },
            {
                title: '熵值',
                dataIndex: 'entropy',
                key: 'entropy'
            },
        ]

        // 签名信息
        const peSignatures = pe.peid_signatures

        // PE资源
        const peResources = pe.pe_resources
        const resourcesColumns = [
            {
                title: '资源名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '资源类型',
                dataIndex: 'filetype',
                key: 'filetype'
            },
            {
                title: '资源大小',
                dataIndex: 'size',
                key: 'size'
            },
            {
                title: '偏移地址',
                dataIndex: 'offset',
                key: 'offset'
            },
            {
                title: '语言',
                dataIndex: 'language',
                key: 'language'
            },
            {
                title: '子语言',
                dataIndex: 'sublanguage',
                key: 'sublanguage'
            },
        ]

        return (
            <div className="network-behavior v-card">
                <div className="header">
                    {/* <img src={peIcon} alt="" style={{ width: '24px' }} /> */}
                    <CodeSandboxOutlined className='header_icon' />
                    <div>
                        <span className="header-name">PE信息</span>
                        <span className="english-name">PE Info</span>
                    </div>
                </div>
                <div className="tabs-container">
                    <div className="card-container">
                        {/* <div>
                            <div className="sub_title">签名信息</div>
                            <div>
                                {peSignatures?.length === 0 ? <Empty description={false} /> :
                                    <Row className="pe_signer_wrap">
                                        <Col flex="100px" className="pe_signer">
                                            <div className="">签名者</div>
                                        </Col>
                                        <Col flex="auto">
                                            <List
                                                size="small"
                                                dataSource={peSignatures}
                                                renderItem={item => <List.Item>{item}</List.Item>}
                                            />
                                        </Col>
                                    </Row>}
                            </div>
                        </div> */}
                        <div>
                            <div className="sub_title" id="pe-import">导入表</div>
                            <div>
                                {peImports?.length === 0 ? <Empty description={false} /> :
                                    <Table rowKey={generateRowKey} columns={columns} dataSource={peImports} pagination={{ hideOnSinglePage: true }} expandable={{
                                        expandedRowRender: expandedRowRender,
                                        rowExpandable: record => record.imports.length > 0,
                                    }} />}
                            </div>
                        </div>
                        <div>
                            <div className="sub_title" id="pe-export">导出表</div>
                            <div>
                                {peExports?.length === 0 ? <Empty description={false} /> :
                                    <Table rowKey={generateExportsRowKey} columns={exportsColumns} dataSource={peExports} pagination={{ hideOnSinglePage: true }} />}
                            </div>
                        </div>
                        <div>
                            <div className="sub_title" id="pe-section">PE节区</div>
                            <div>
                                {peSections?.length === 0 ? <Empty description={false} /> :
                                    <Table rowKey={(record) => `${record.name}_${record.entropy}`} columns={sectionColumns} dataSource={peSections} pagination={{ hideOnSinglePage: true }} />}
                            </div>
                        </div>
                        <div>
                            <div className="sub_title" id="pe-resource">PE资源</div>
                            <div>
                                {peResources?.length === 0 ? <Empty description={false} /> :
                                    <Table rowKey={(record) => `${record.name}_${Math.random()}`} columns={resourcesColumns} dataSource={peResources} pagination={{ hideOnSinglePage: true }} />}
                            </div>
                        </div>
                        {/* <Collapse defaultActiveKey={['1', '2', '3', '4', '5']} ghost>
                            <Panel header="签名信息" key="1" showArrow={false}>
                                <div>
                                    {peSignatures?.length === 0 ? <Empty description={false} /> :
                                        <Row className="pe_signer_wrap">
                                            <Col flex="100px" className="pe_signer">
                                                <div className="">签名者</div>
                                            </Col>
                                            <Col flex="auto">
                                                <List
                                                    size="small"
                                                    dataSource={peSignatures}
                                                    renderItem={item => <List.Item>{item}</List.Item>}
                                                />
                                            </Col>
                                        </Row>}
                                </div>
                            </Panel>
                            <Panel header="导入表" key="2">
                                {peImports?.length === 0 ? <Empty description={false} /> : <ExpandableContent>
                                    <Table rowKey={generateRowKey} columns={columns} dataSource={peImports} pagination={{ hideOnSinglePage: true }} expandable={{
                                        expandedRowRender: expandedRowRender,
                                        rowExpandable: record => record.imports.length > 0,
                                    }} /></ExpandableContent>}
                            </Panel>
                            <Panel header="导出表" key="3">
                                {peExports?.length === 0 ? <Empty description={false} /> :
                                    <Table rowKey={generateExportsRowKey} columns={exportsColumns} dataSource={peExports} pagination={{ hideOnSinglePage: true }} />}
                            </Panel>
                            <Panel header="PE节区" key="4">
                                {peSections?.length === 0 ? <Empty description={false} /> :
                                    <Table size={'small'} rowKey={(record) => `${record.name}_${record.entropy}`} columns={sectionColumns} dataSource={peSections} pagination={{ hideOnSinglePage: true }} />}
                            </Panel>
                            <Panel header="PE资源" key="5">
                                {peResources?.length === 0 ? <Empty description={false} /> :
                                    <Table size={'small'} rowKey={(record) => `${record.name}_${Math.random()}`} columns={resourcesColumns} dataSource={peResources} pagination={{ hideOnSinglePage: true }} />}
                            </Panel>
                        </Collapse> */}

                    </div>
                </div>
            </div>
        );
    }
}

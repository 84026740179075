// @ts-nocheck
import React, { Component } from 'react';
import { Card, Space, Empty, List, Typography, Pagination } from 'antd';
import { formatDate } from '../utils/index';
import axios from '../api/index'
import { URLSecurityInfos } from '../api/config'
import gps from "../images/Icon/gps.svg";


const { Text, Link, Paragraph } = Typography;

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1, // 当前页码
            pageSize: 10, // 每页展示的数量
            ordering: '', // 每页展示的数量
            newsResults: [], // 每页展示的数量
            total: 0,
        };
    }
    // 处理页码变更的函数
    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber }, () => {
            this.fetchNewsResults();
        }
        );
    };

    fetchNewsResults = async () => {
        try {
            const response = await axios.get(URLSecurityInfos, {
                params: {
                    page: this.state.currentPage,
                    page_size: this.state.pageSize,
                    ordering: this.state.ordering,
                },
            });
            this.setState({ newsResults: response.data.results, total: response.data.count });
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    componentDidMount() {
        console.log('fetchNewsResults', this.props)
        this.fetchNewsResults()
    }

    render() {
        // const { newsResults } = this.props;
        const { currentPage, pageSize, newsResults, total } = this.state;
        // const startIndex = (currentPage - 1) * pageSize;
        // const endIndex = startIndex + pageSize;
        // const paginatedNews = newsResults.slice(startIndex, endIndex);
        console.log('this.state', this.state)
        return (
            <div style={{
                width: '1000px',
                margin: 'auto',
                background: 'white',
                padding: '36px 40px',
            }}>
                <div style={{
                    fontSize: '20px',
                    color: 'rgba(0, 0, 0, 0.88)',
                    fontWeight: 'bold',
                    display: 'flex',
                    gap: '7px',
                }}><img src={gps} alt="" />工控安全情报</div>
                <List
                    itemLayout="vertical"
                    dataSource={newsResults}
                    renderItem={(item) => (
                        <List.Item>
                            <Card
                                hoverable
                                style={{ width: '100%' }}
                                bordered={false}
                            // cover={
                            //     <img
                            //         alt="example"
                            //         src="https://os.alipayobjects.com/rmsportal/QBnOOoXhtfFWryL.png"
                            //         height={160}
                            //         style={{ objectFit: 'cover' }}
                            //     />
                            // }
                            >
                                <Card.Meta
                                    title={
                                        <Link href={item.link} target="_blank" style={{ color: 'rgba(0, 24, 71, 1)', fontSize: '20px' }}>
                                            {item.title}
                                        </Link>
                                    }

                                    description={
                                        <>
                                            <Paragraph ellipsis={{ tooltip: false, rows: 3 }} style={{ color: 'rgba(0, 0, 0, 0.65)', }}>{item.summary}</Paragraph>
                                            <Text style={{ color: 'rgba(0, 0, 0, 0.65)' }}>来源：</Text>{' '}
                                            <Text style={{ marginRight: '20px', color: 'rgba(0, 0, 0, 0.65)' }}>{item.info_src}</Text>
                                            <Text type="secondary">{formatDate(item.publish_time)}</Text>
                                        </>
                                    }
                                />
                            </Card>
                        </List.Item>
                    )}
                />
                <Pagination
                    current={currentPage}
                    total={total}
                    pageSize={pageSize}
                    onChange={this.handlePageChange}
                    showSizeChanger={false}
                    showQuickJumper={false}
                    style={{ textAlign: 'right', marginTop: '20px' }}
                />
            </div>
        );
    }
}

export default News;


import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import axios from '../api/index';
import { URLRolePermissions } from '../api/config';

const PermissionManage = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(URLRolePermissions); // 假设这是你的API路径
            setData(response.data);
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    };

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
        },
        {
            title: '标签',
            dataIndex: 'label',
        },
    ];

    return (
        <div>
            <Table
                columns={columns}
                dataSource={data.map(item => ({
                    ...item,
                    key: item.name,
                }))}
            />
        </div>
    );
};

export default PermissionManage;
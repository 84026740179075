// @ts-nocheck
import { Component } from "react";
import {
    DownloadOutlined, SearchOutlined, FileTextOutlined, RedoOutlined, StopOutlined, ClockCircleOutlined, CloseCircleOutlined, CheckCircleOutlined,
    ProfileOutlined
} from '@ant-design/icons';
import { Button, Space, Divider, Descriptions, Tooltip, Tag, message } from "antd";
import Moment from "moment";
import React from 'react';
import * as echarts from 'echarts';
import 'echarts-liquidfill'
import { formatDate, go } from '../utils/index';
import { URLSubmittedFile, URLPcapFile, URLReanalysis } from '../api/config'
import { handleDownload } from '../api/index'
import axios from "../api/index";
import surveyLineIcon from "../images/Icon/survey-line.svg";
import linuxIcon from "../images/osType/linux.svg";
import windows7Icon from "../images/osType/windows7.svg";
import windows10Icon from "../images/osType/windows10.svg";
import windows11Icon from "../images/osType/windows11.svg";
import checkboxCircleIcon from "../images/Icon/checkbox-circle-line.svg";
import searchEyeIcon from "../images/Icon/search-eye-line.svg";
import score0Frame from "../images/scoreFrame/score=0.svg";
import score3Frame from "../images/scoreFrame/score=3.svg";
import score7Frame from "../images/scoreFrame/score=7.svg";
import score10Frame from "../images/scoreFrame/score=10.svg";

class Chart extends Component<any, any> {
    getColor(score: number) {
        // 分数球的颜色：10分红色，9-7分黄色，6-0分绿色，字体颜色换个和背景色有区分的
        // if (score < 7) {
        //     return 'green'
        // } else if (score < 9) {
        //     return 'yellow'
        // } else {
        //     return 'red'
        // }
        if (score < 3) {
            return 'rgba(82, 196, 26, 1)'
        } else if (score < 7) {
            return 'rgba(250, 219, 20, 1)'
        } else if (score < 9) {
            return 'rgba(250, 140, 22, 1)'
        } else {
            return 'rgba(255, 77, 79, 1)'
        }
    }
    getLiquidChart = () => {
        const { score } = this.props;
        console.log('chart this.props', this.props)
        const percent = score / 10
        const chart = echarts.init(document.getElementById('liquid'));
        const scoreMap = [0, 6, 7, 9, 10]
        const color = this.getColor(score)
        const option = {
            series: [{
                type: 'liquidFill',
                data: [percent],
                radius: '80%',
                color: [color, '#0f0', 'rgb(0, 0, 255)'],
                outline: {
                    show: true,
                    borderDistance: 8,
                    itemStyle: {
                        color: 'none',
                        borderColor: color,
                        borderWidth: 8,
                        shadowBlur: 20,
                        shadowColor: 'rgba(0, 0, 0, 0.25)'
                    }
                },
                label: {
                    show: true,
                    color: '#ffffff',
                },
            }]
        };
        chart.setOption(option, true);
    };

    componentDidMount() {
        // this.getLiquidChart()
    }


    render() {
        // @ts-ignore
        // return <div id="liquid" style={{ width: '600px', height: '600px' }} />;
        const { score } = this.props;
        const getImgSrcByScore = (score: number) => {
            if (score < 3) {
                return score0Frame
            } else if (score < 7) {
                return score3Frame
            } else if (score < 9) {
                return score7Frame
            } else {
                return score10Frame
            }
        }
        return <div style={{ position: 'relative' }}>
            <img src={getImgSrcByScore(score)} alt="" />
            <div className="score_text" style={{color: this.getColor(score)}}>{score}分</div>
        </div>;
    }

}

export class Summary extends Component<any, any> {


    render() {
        const file_path = process.env.REACT_APP_FILE_PATH
        const { _id, target, info, submitted, tasks } = this.props;
        Moment.locale("zh-cn");
        const TimeHuman = (data: string) => {
            return Moment(data, "YYYY-MM-DD HH:mm:ss")
        }
        let seconds = TimeHuman(info.ended).diff(TimeHuman(info.started), "seconds")
        let min = (seconds / 60).toFixed(2)

        const SizeHuman = (size: number) => {
            if (size < 1024) return size + " Bytes";
            size /= 1024
            if (size < 1024) return size.toFixed(2) + " KB"
            size /= 1024
            if (size < 1024) return size.toFixed(2) + " MB"
            return "未知大小";
        }
        const machineHuman = (machine: string) => {
            if (machine === "192.168.1.6") return '三菱-控制系统';
            if (machine === "192.168.1.8") return '西门子-控制系统';
            if (machine === "192.168.1.5") return '施耐德-控制系统';
            if (machine === "192.168.1.7") return '罗克韦尔-控制系统';
            if (machine === "192.168.1.4") return '欧姆龙-控制系统';
            if (machine === "192.168.1.3") return 'GE-控制系统';


        }
        const downloadSample = (id) => {
            handleDownload(URLSubmittedFile(id))
        }
        const downloadPcap = (id, taskId) => {
            handleDownload(URLPcapFile(id, taskId))
        }
        const reanalysis = async (id) => {
            try {
                const { data } = await axios.get(URLReanalysis(id), undefined, { a: 1 })
                message.success('重新分析成功')
                go(`/detail/${data.analysis_id}`)
                window.location.reload()

            } catch (error) {
                message.warn(error?.response?.data?.message ?? '分析中')
            }

        }

        const getOsIcon = (platform, os_version) => {
            // console.log('getOsIcon', platform, os_version)
            const map = {
                'windows10': windows10Icon,
                'windows11': windows11Icon,
                'windows7': windows7Icon,
                'linux': linuxIcon,
            }
            try {
                return map[platform + os_version]
            } catch (error) {
                return map['linux']
            }
        }

        const stateMap = (state) => {
            const map = {
                'no_selected': {
                    icon: <StopOutlined />,
                    text: '不支持分析',
                    color: '#ffe58f',
                },
                'untracked': {
                    icon: <ClockCircleOutlined />,
                    text: '未开始',
                    color: '#000000d9',
                },
                'finished': {
                    icon: <CheckCircleOutlined />,
                    text: '已完成',
                    color: '#00000073',
                },
                'fatal_error': {
                    icon: <CloseCircleOutlined />,
                    text: '失败',
                    color: '#ff4d4f',
                },
            }
            try {
                return map[state] || {
                    icon: <SearchOutlined />,
                    text: '分析中',
                    color: '#52C41A',
                }
            } catch (error) {
                return {
                    icon: <SearchOutlined />,
                    text: '分析中',
                    color: '#52C41A',
                }
            }
        }

        if (submitted.category === "file") {
            console.log(submitted, 'submitted')
            return (
                <div className="v-card">
                    <div className="header">
                        {/* <img src={surveyLineIcon} alt="" /> */}
                        <ProfileOutlined className="header_icon" />
                        <div>
                            <span className="header-name">基础信息</span>
                            <span className="english-name">Overview</span>
                        </div>
                    </div>
                    <div className="board flex-between">
                        <div className="content">
                            <Descriptions title={submitted.filename} labelStyle={{ color: 'rgba(0, 0, 0, 0.45)' }} column={6} size="small" style={{ marginBottom: '20px' }}>
                                {/* <Descriptions.Item label="" span={3} contentStyle={{ fontSize: '16px', fontWeight: 500 }}>{submitted.filename}</Descriptions.Item> */}
                                <Descriptions.Item label="文件大小" span={2}>{SizeHuman(submitted.size)}</Descriptions.Item>
                                <Descriptions.Item label="文件类型" span={4}>{submitted.type}</Descriptions.Item>
                                <Descriptions.Item label="开始分析时间" span={2}>{formatDate(submitted.created_on)}</Descriptions.Item>
                                {/* <Descriptions.Item label="分析结束时间" span={3}>{info.ended}</Descriptions.Item> */}
                                <Descriptions.Item label="分析状态" span={4} contentStyle={{ color: stateMap(submitted.state).color, display: 'flex', alignItems: 'center', gap: '5px' }}>{stateMap(submitted.state).icon}{stateMap(submitted.state).text}</Descriptions.Item>

                                <Descriptions.Item label="运行平台" span={6}>{submitted.platforms.map((item, index) => {
                                    return (<Tag key={item.platform + item.os_version + index}><img src={getOsIcon(item.platform, item.os_version)} style={{ width: '16px', height: '16px', marginRight: '5px' }} />{item.platform + item.os_version}</Tag>)
                                })}</Descriptions.Item>

                                {/* <Descriptions.Item label="分析机" span={3}>{machineHuman(info.machine.label)}</Descriptions.Item> */}

                                {/* <Descriptions.Item label="总用时" span={3}>{min + " min"}</Descriptions.Item> */}
                            </Descriptions>
                            <Descriptions title="HASH" labelStyle={{ color: 'rgba(0, 0, 0, 0.45)', width: '80px' }} size="small" style={{ marginBottom: '20px' }}>
                                <Descriptions.Item label="MD5" span={3}>{submitted.md5}</Descriptions.Item>
                                <Descriptions.Item label="SHA1" span={3}>{submitted.sha1}</Descriptions.Item>
                                <Descriptions.Item label="SHA256" span={3}>{submitted.sha256}</Descriptions.Item>
                            </Descriptions>

                            <Space>
                                {/* <Button type="primary" shape="round" icon={<DownloadOutlined />}
                                onClick={() => window.open(process.env.REACT_APP_API_ENDPOINT + "main/report/" + _id)}>报告下载</Button> */}
                                <Button type="primary" icon={<DownloadOutlined />}
                                    onClick={() => downloadPcap(_id, tasks[0].id)}>流量下载</Button>
                                <Button onClick={() => downloadSample(_id)} icon={<FileTextOutlined />}>样本下载</Button>
                                <Button onClick={() => reanalysis(_id)} icon={<RedoOutlined />}>重新分析</Button>
                            </Space>
                        </div>
                        <div className='score'>
                            <Chart score={submitted.score} />
                        </div>
                    </div>
                </div>

            )
        } else if (target.category === "url") {
            return (
                <div className="board flex-between v-card">
                    <div className="content">
                        <table className="table-no-border">
                            <tr>
                                <td>
                                    <span>URL: </span>
                                    <span className="pl-1">{target.url}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>分析机: </span>
                                    <span className="pl-1">TODO: XXX上位机</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>任务的提交时间: </span>
                                    <span className="pl-1"> {info.added}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>最近检测时间: </span>
                                    <span className="pl-1"> {info.started}</span>
                                </td>
                            </tr>
                        </table>
                        <Divider orientation="left">操作</Divider>
                        <Space>
                            <Button type="primary" shape="round" icon={<DownloadOutlined />}
                                onClick={() => window.open(file_path + info.id + "/dump.pcap")}>流量下载</Button>
                            <Button disabled={true}>样本下载(暂不支持下载)</Button>
                            <Tooltip title="search">
                                <Button
                                    href={`https://www.virustotal.com/en/file/` + info.sha256 + `/analysis/`}
                                    target="_blank" type="primary" icon={<SearchOutlined />}>点击查询</Button>
                            </Tooltip>
                        </Space>
                    </div>
                </div>
            )
        } else {
            return <span>未知类型</span>
        }
    }
}

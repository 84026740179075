// components/NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>404 - 页面未找到</h1>
      <p>很抱歉，你请求的页面不存在。</p>
    </div>
  );
};

export default NotFound;
// @ts-nocheck
import { Component, useEffect, useContext, useState } from 'react';
import { Route, Redirect, matchPath, withRouter, useHistory } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { AuthContext } from '../context/AuthContext';
import { message, Avatar, Dropdown, Space, Menu } from 'antd';
import { go } from '../utils';
import HeaderSearch from "./HeaderSearch";
import { UserOutlined, CaretDownOutlined, LogoutOutlined, SolutionOutlined } from '@ant-design/icons';


interface Props {
  routes: RouteProps[];
}

interface ContentState {
  isAuthenticated: boolean;
}

class Content extends Component<Props, ContentState> {

  isTargetRouteValid = (pathname: string, routes: RouteProps[]): boolean => {
    const targetRouterConfig = routes.find((item) => {
      // console.log('matchpath', pathname, JSON.stringify(item), matchPath(pathname, item))
      const matched = matchPath(pathname, item);
      return matched?.isExact;
    });
    return !!targetRouterConfig;
  }

  // private requiresAuthentication = (pathname: string, routes: RouteProps[]): boolean => {
  //   const targetRouterConfig = routes.find((item) => item.path === pathname);
  //   return targetRouterConfig?.auth ?? false;
  // }

  shouldRedirectToLogin = (): boolean => {
    // return !isAuthenticated && (this.isTargetRouteValid(this.props.location.pathname, this.props.routes) || !this.requiresAuthentication(this.props.location.pathname, this.props.routes));
    // @ts-ignore
    return !this.state.isAuthenticated && this.isTargetRouteValid(this.props.location.pathname, this.props.routes)
  }

  shouldRedirectTo404 = (isAuthenticated: boolean, routes: RouteProps[]): boolean => {
    // @ts-ignore
    return isAuthenticated && !this.isTargetRouteValid(this.props.location.pathname, routes);
  }

  // 新增一个方法来检查sessionStorage中是否存在token
  // private checkAuthentication = () => {
  //   const token = sessionStorage.getItem('ACCESS_TOKEN');
  //   return Boolean(token); // 如果token存在则返回true，否则返回false
  // };

  constructor(props: Props) {
    super(props);
    this.state = {
      isAuthenticated: true,
    };
  }

  componentDidUpdate() {
    // console.log('props', prevProps, this.props)
  }

  render() {
    // console.log('layout render token', sessionStorage.getItem('ACCESS_TOKEN'))
    const { location } = this.props;
    const { pathname } = location;
    // const isAuthenticated = true; // 假设这是从sessionStorage获取用户名的逻辑
    // const isAuthenticated = !!sessionStorage.getItem('ACCESS_TOKEN'); // 假设这是从sessionStorage获取用户名的逻辑
    const { isAuthenticated } = this.state;
    // console.log('layout 是否有权限', isAuthenticated, 'this.props.routes', this.props.routes)
    if (this.shouldRedirectToLogin()) {
      return <Redirect to="/login" />;
    } else if (this.shouldRedirectTo404(isAuthenticated, this.props.routes)) {
      return <Redirect to="/404" />;
    } else {
      const targetRouterConfig = this.props.routes.find((item) => {
        const matched = matchPath(pathname, item);
        return matched?.isExact;
      });
      return targetRouterConfig ? (
        <Route exact path={pathname} component={targetRouterConfig.component} />
      ) : null;
    }
  }
}


class Header extends Component<any, any> {
  static contextType = AuthContext;
  handleGo = () => {
    go('/#/search')
  }
  render() {
    const menu = (
      <Menu>
        <Menu.Item><a href="/#/task"><SolutionOutlined />个人中心</a></Menu.Item>
        <Menu.Item><a href="/#/login"><LogoutOutlined />退出登录</a></Menu.Item>
      </Menu>
    );
    const { isAuthenticated, user } = this.context;
    console.log('header isAuthenticated', isAuthenticated, user)
    return (
      <nav className="navbar flex-between">
        <div className="nav-left flex-between">
          <a href="/#/"><img className="navbar-brand" alt="brand" src="/assets/images/logo-full.png" /></a>
          <HeaderSearch />
        </div>
        <div className="nav-right flex-between">
          <ul className="navbar-nav flex-between">
            <li className="nav-item">
              <a href="/#/upload">样本上传</a>
            </li>
            <li className="nav-item">
              <a href="/#/search">分析报告</a>
            </li>
            <li className="nav-item">
              <a href="/#/news">安全情报</a>
            </li>
            {/* <li className="nav-item">
              <a href="/#/task">任务列表</a>
            </li> */}
            {/* <li className="nav-item">
              <a href="/#/list">list</a>
            </li> */}
            {isAuthenticated ? (
              <div style={{padding: '0 20px'}}>
                <Space>
                  <Avatar size={32} icon={<UserOutlined />} />
                  <Dropdown overlay={menu}>
                    <a onClick={e => e.preventDefault()} style={{ color: 'white' }}>
                      <Space>
                        {user || sessionStorage.getItem('username')}
                        <CaretDownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </Space>
              </div>
            ) : (<li className="nav-item">
              <a href="/#/login">登录</a>
            </li>)}
            {isAuthenticated ? null : (<li className="nav-item">
              <a href="/#/register">注册</a>
            </li>)}
            {/* {isAuthenticated ? (<li className="nav-item">
              <a href="/#/login">退出</a>
            </li>) : null} */}
          </ul>
        </div>
      </nav>
    );
  }
}


class Footer extends Component<any, any> {
  render() {
    return (
      <footer className="layout-footer text-center py-4">
        <a href="/#/"><img style={{
          width: '300px',
        }} alt="brand" src="/assets/images/sjtunblogo.png" /></a>
        <p style={{
          color: 'rgba(255, 255, 255, 0.65)', marginBottom: '0px'
        }} >联系邮箱：icssandbox@sjtu-naii.com</p>
        <p style={{
          color: 'rgba(255, 255, 255, 0.65)', marginBottom: '0px'
        }} >版权所有 © 2024 上海交通大学宁波人工智能研究院 All Rights Reserved</p>
      </footer>
    );
  }
}

export {
  Header,
  Content,
  Footer
}
import React, { Component } from 'react';
import { Upload, message, Button, Checkbox, Form, Select, Input, Modal, Spin, Typography, Descriptions } from 'antd';
import { InboxOutlined, CodeSandboxOutlined } from '@ant-design/icons';
import axios from './api/index'
import { URLSubmitFile, URLAnalysisStatic, URLSubmitPlatforms, URLStartAnalysis } from './api/config'
// @ts-ignore
import ReactCanvasNest from 'react-canvas-nest';
import { withRouter } from 'react-router-dom';
import { go, formatDate } from './utils';
import microscope from "./images/Icon/microscope.svg";
import timeZone from "./images/Icon/time-zone.svg";
import hours from "./images/Icon/24-hours.svg";
import calendarSchedule from "./images/Icon/calendar-schedule.svg";
import fileIcon from "./images/2.5DLogo/file.svg";
import StaticFeature from './Modules/StaticFeature';
import type { FormInstance } from 'antd/es/form';


const { Dragger } = Upload;
const { Text } = Typography;

class SubmitFile extends Component<any, any> {
    formRef = React.createRef<FormInstance>();

    state = {
        machines: [],
        selectedMachine: 'default',
        count: [],
        userIp: [],
        staticData: null,
        fileList: [],
        additionalProps: {
            platforms: {
                "platform": '',
                "os_version": '',
            },
            command: undefined,
        },
        options: [],
        submitted: {
            name: '',
            analysis_id: '',
            md5: '',
            media_type: '',
        },
        selectedValue: undefined,
        agree: true,
        loading: false,
    }

    customRequest = async options => {
        this.setState({ loading: true });

        const { file, onSuccess, onError, onProgress } = options;

        try {
            // 获取文件
            const formData = new FormData();
            formData.append('file', file);
            let o = { timeout: 30 }
            formData.append('settings', JSON.stringify(o));

            // const token = sessionStorage.getItem('ACCESS_TOKEN')
            // token && (config.headers.Authorization = 'Bearer ' + token)

            // 发送POST请求到你的服务器API
            const response = await axios.post(URLSubmitFile, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // Authorization: 'Bearer ' + token,
                },
                // onUploadProgress: progressEvent => {
                //   const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                //   onProgress({ percent: percent }, file);
                // },
            });

            // 根据服务器响应处理成功或失败
            if (response.status === 200 || response.status === 201) {
                console.log(response.data);
                // onSuccess();
                message.success('文件上传成功')
                onSuccess()
                this.setState({ submitted: response.data })
            } else {
                onError();
                message.error('文件上传失败');
            }
        } catch (error) {
            onError({ error });
            message.error(error.response.data.message);
        } finally {
            this.setState({ loading: false });
        }
    };

    componentWillMount() {
    }

    uploadFile() {
        let el = document.getElementById("upload__input");
        if (el === null) return;
        el.click()
    }
    userIP() {
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'main/userIp').then(response => {
            if (response.status === 200) {
                const { data } = response;
                this.setState({ userIp: data })
                console.log(data)
            } else {
                message.error("从服务器获取信息失败.")
                console.error(response)
            }
        })
    }

    componentDidMount() {
        // this.checkAuthentication();
        this.getAnalysisStatic();
        this.fetchOptions();
    }

    componentDidUpdate() {
        // this.checkAuthentication();
    }

    checkAuthentication = () => {
        const token = sessionStorage.getItem('ACCESS_TOKEN'); // 假设token存储在这里
        if (!token) {
            // message.info('请先登录');
            // this.props.history.push('/login');
            message.info('请先登录', 0.5, () => {
                // 这里写你希望在提示框关闭后执行的代码
                this.props.history.push('/login');
            });
        }
    }

    getAnalysisStatic = () => {
        axios.get(URLAnalysisStatic).then((res) => {
            const { data } = res;
            this.setState({ staticData: data });
        });
    };

    handleChangeCommand = (e: { target: { value: any; }; }) => {
        const { value } = e.target;
        this.setState(prevState => ({
            additionalProps: {
                ...prevState.additionalProps,
                command: value,
            },
        }))
    }

    handleChangePlatform = (value) => {
        console.log('value', value)
        const data = value.label.props['data-info']
        this.setState(prevState => ({
            additionalProps: {
                ...prevState.additionalProps,
                platforms: data,
            },
        }))
    }

    fetchOptions = async () => {
        try {
            const response = await axios.get(URLSubmitPlatforms);
            const options = response.data.map((item: { platform: string; os_version: string }) => ({
                // label: `${item.platform}${item.os_version}`,
                // value: `${item.platform}${item.os_version}`,
                label: (
                    <span data-info={{ platform: item.platform, os_version: item.os_version }}>
                        {item.platform} {item.os_version}
                    </span>
                ),
                value: item.os_version,
            }));
            this.setState({ options });
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    deleteFile = () => {
        this.setState({
            fileList: []
        })
    };

    startAnalyse = async () => {
        const { submitted, additionalProps } = this.state;
        await this.formRef.current!.validateFields();
        try {
            console.log('additionalProps.command', additionalProps.command)
            const data = {
                platforms: [{
                    "platform": additionalProps.platforms.platform,
                    "os_version": additionalProps.platforms.os_version,
                }],
                "command": additionalProps.command ? [additionalProps.command] : undefined,
            }
            // 发送POST请求到你的服务器API
            const response = await axios.post(URLStartAnalysis(submitted.analysis_id), data);

            // 根据服务器响应处理成功或失败
            if (response.status === 200 || response.status === 201) {
                Modal.success({
                    content: '正在分析……',
                    onOk: () => {
                        this.deleteFile()
                        this.setState({
                            agree: true,
                        }, () => {
                            go('/task')
                        })
                    }
                });
            } else {
                message.error('文件上传失败');
            }
        } catch (error) {
            message.error(error.response.data.message);
        }
    };

    onAgreeChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
        this.setState({
            agree: e.target.checked
        })
    };

    render() {
        const token = sessionStorage.getItem('ACCESS_TOKEN');

        const props = {
            disabled: !token,
            name: 'file',
            multiple: false,
            action: '/api/v1' + URLSubmitFile,
            // showUploadList: false,
            beforeUpload: (file: any) => {
                if (file.size > 102400000) {
                    message.error("暂仅支持不超过100MB的.exe文件");
                    return false
                }
                return true
            },
            data: {
                timeout: 30
            },
            showUploadList: false,
            onChange: (info: { file: { name?: any; status?: any; }; fileList: any; }) => {
                console.log('fileList', info.fileList);
                const { status } = info.file;
                if (status === 'done') {
                    this.setState({
                        fileList: info.fileList,
                    })
                } else if (status === 'error') {
                    // const hide = message.loading(`${info.file.name} 文件上传错误，请将错误信息告知管理员`, 0);
                    // setTimeout(hide, 2500);
                }
            },
        }
        const { selectedMachine, staticData, fileList, additionalProps, options, submitted, selectedValue, agree, loading } = this.state;
        return (
            <main className="main">
                <Spin spinning={loading}>
                    <div className='login_title' style={{ margin: '0 auto' }}>
                        <CodeSandboxOutlined className='login_title_icon' />
                        <div>
                            <div className='login_title_cn'>工控情报中心</div>
                            <div className='login_title_en'>ICS Threat Intelligence Center</div>
                        </div>
                    </div>
                    {fileList.length > 0 ? <div className="submitted_wrap">

                        <div className='file_info'>
                            <div className='file_info_title'>
                                <span>样本信息</span>
                                <Button onClick={this.deleteFile}>删除样本</Button>
                            </div>
                            <div className='file_info_content'>
                                <div>
                                    <img src={fileIcon} alt="" style={{ width: '100px', height: '111px', margin: '0px 42px' }} />
                                </div>
                                <div>
                                    <Descriptions column={1} labelStyle={{ width: '90px' }}>
                                        <Descriptions.Item label="样本名称">{submitted.name}</Descriptions.Item>
                                        <Descriptions.Item label="文件类型">{submitted.media_type}</Descriptions.Item>
                                        <Descriptions.Item label="MD5">{submitted.md5}</Descriptions.Item>
                                    </Descriptions>
                                    {/* <p className='file_info_key'>样本名称</p>
                                    <p>{submitted.name}</p>
                                    <p className='file_info_key'>文件类型</p>
                                    <p>{submitted.media_type}</p>
                                    <p className='file_info_key'>MD5</p>
                                    <p>{submitted.md5}</p> */}
                                </div>
                            </div>
                        </div>
                        <div className='additional_props'>
                            <div style={{
                                fontWeight: 600,
                                fontSize: '16px',
                                marginBottom: '25px',
                            }}>附加信息</div>
                            <Form
                                ref={this.formRef}
                                labelCol={{ span: 3 }}
                            >
                                <Form.Item label="靶场" name="selectedValue" rules={[{ required: true, message: '请选择靶场!' }]}>
                                    <Select placeholder="选择靶场" value={selectedValue}
                                        onChange={this.handleChangePlatform}
                                        options={options}
                                        labelInValue
                                    />
                                </Form.Item>
                                <Form.Item label="自定义命令" name="command">
                                    <Input.TextArea placeholder="请使用%PAYLOAD%代替样本名，如：%PAYLOAD% -t 100" value={additionalProps.command}
                                        onChange={this.handleChangeCommand} rows={3} />
                                </Form.Item>
                                {additionalProps.command ? (<div>
                                    <Form.Item label="完整命令预览" name="">
                                        <Text code>{`${additionalProps.command}`.replace('%payload%', submitted.name)}</Text>
                                    </Form.Item>
                                </div>) : null}
                            </Form>
                        </div>
                    </div> : <div className="submit_main">
                        <div>
                            <div className='flex overview_data'>
                                <div className='overview_data_icon'>
                                    <img src={microscope} alt="" />
                                </div>
                                <div className='overview_data_text'>
                                    <label>已分析样本</label>
                                    <div>
                                        <span className='overview_data_num'>{staticData?.target_count}</span>
                                        <span>个</span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex overview_data'>
                                <div className='overview_data_icon'>
                                    <img src={timeZone} alt="" />
                                </div>
                                <div className='overview_data_text'>
                                    <label>已分析时长</label>
                                    <div>
                                        <span className='overview_data_num'>{(staticData?.run_time / 3600).toFixed(0)}</span>
                                        <span>小时</span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex overview_data'>
                                <div className='overview_data_icon'>
                                    <img src={hours} alt="" />
                                </div>
                                <div className='overview_data_text'>
                                    <label>24小时分析任务</label>
                                    <div>
                                        <span className='overview_data_num'>{staticData?.daily_count}</span>
                                        <span>个</span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex overview_data'>
                                <div className='overview_data_icon'>
                                    <img src={calendarSchedule} alt="" />
                                </div>
                                <div className='overview_data_text'>
                                    <label>规则更新时间</label>
                                    <div>
                                        <span className='overview_data_num'>{formatDate(staticData?.rule_update_time, 'YYYY-MM-DD')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='uploader'>

                            <Dragger {...props} customRequest={this.customRequest}
                                data={{ "selectedMachine": selectedMachine }} style={{ background: 'white', border: 'none' }}>
                                <p className="ant-upload-drag-icon">
                                    <img src={fileIcon} alt="" style={{ width: '229px', height: '252px', }} />
                                </p>
                                <p className="ant-upload-text">{!token ? '请先登录后上传文件' : '点击或拖拽文件到此处上传'}</p>
                                <p className="ant-upload-hint">
                                    暂仅支持不超过100MB的.exe文件
                                </p>
                            </Dragger>

                        </div>

                    </div>}

                    <div style={{ textAlign: 'center' }}>
                        <Button onClick={this.startAnalyse} type='primary' disabled={!token || !agree || fileList.length < 1} style={{ width: '320px', marginBottom: '7px' }}>开始分析</Button>
                        {/* <div>
                        <Checkbox checked={agree} onChange={this.onAgreeChange}>我已阅读并遵守《信息网络传播权保护条例》</Checkbox>
                    </div> */}
                    </div>
                </Spin>
            </main >


        );
    }
}
export default withRouter(SubmitFile);

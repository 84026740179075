// @ts-nocheck
import React, { Component } from 'react';
import { Card, Input, Button, Select, Descriptions, message, Space, Empty, Table } from 'antd';
import axios from '../api/index'
import { URLTagsFamilies, URLTargets, URLSecurityInfos } from '../api/config'

import News from "./News";
import { Redirect, Link, useParams, useLocation } from "react-router-dom";
import { formatDate } from '../utils/index';
import { go } from '../utils/index';

const { Option } = Select;
const { Column } = Table

class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            familyOptions: ['adwind', 'amadey', 'conti'],
            tagOptions: ['trojan', 'stealer', 'miner', 'ransomware'],
            results: [],
            isAdvancedSearchOpen: false,
            selectedFamily: undefined,
            selectedTag: undefined,
            newsResults: [],
            showNews: true,
            total: 0,
            currentPage: 1,
            pageSize: 10,
            ordering: '-analysis__created_on',
        };
    }

    componentDidMount() {
        console.error('componentDidMount:');
        // this.fetchNewsResults()
        console.log('this.props.location', this.props.location)
    }

    handleSearchChange = (e) => {
        this.setState({ search: e.target.value });
    };

    handleFamilyChange = (value) => {
        this.setState({ selectedFamily: value });
    };

    handleTagChange = (value) => {
        this.setState({ selectedTag: value });
    };

    toggleAdvancedSearch = () => {
        this.setState((prevState) => ({ isAdvancedSearchOpen: !prevState.isAdvancedSearchOpen }));
    };

    fetchOptions = async () => {
        const response = await axios.get(URLTagsFamilies);
        this.setState({
            familyOptions: response.data.families,
            tagOptions: response.data.tags,
        });
    }

    fetchSearchResults = async () => {
        let url = '/search'
        if (this.state.search) {
            if (url === '/search') {
                url = url + `?search=${this.state.search}`
            } else {
                url = url + `&search=${this.state.search}`
            }
        }

        if (this.state.selectedFamily) {
            if (url === '/search') {
                url = url + `?family=${this.state.selectedFamily}`
            } else {
                url = url + `&family=${this.state.selectedFamily}`
            }
        }

        if (this.state.selectedTag) {
            if (url === '/search') {
                url = url + `?tag=${this.state.selectedTag}`
            } else {
                url = url + `&tag=${this.state.selectedTag}`
            }
        }
        go(url)
    };

    fetchNewsResults = async () => {
        try {
            const response = await axios.get(URLSecurityInfos);
            this.setState({ newsResults: response.data.results });
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    render() {
        const { search, results, isAdvancedSearchOpen, selectedFamily, selectedTag, newsResults, showNews } = this.state;

        return (
            <div>
                <div>
                    {<News newsResults={newsResults} />}
                </div>

            </div>

        );
    }
}

export default SearchPage;
// @ts-nocheck
import { Component } from "react";
import { Table, Tabs, Tag, Timeline, Collapse, Empty, List } from "antd";
import ExpandableContent from './ExpandableContent'
import StaticFeature from "./StaticFeature";
import BasicInfo from "./BasicInfo";
import seoLineIcon from "../images/Icon/seo-line.svg";
import { FolderViewOutlined } from '@ant-design/icons';



const { Panel } = Collapse
const { TabPane } = Tabs;

export class StaticAnalyse extends Component<any, any> {
    render() {
        const { signatures, target } = this.props
        // console.log('StaticAnalyse props', this.props)
        // if (!staticData) {
        //     return <Empty />
        // }


        return (
            <div className="network-behavior v-card">
                <div className="header">
                    {/* <img src={seoLineIcon} alt="" style={{ width: '24px' }} /> */}
                    <FolderViewOutlined className='header_icon' />
                    <div>
                        <span className="header-name">静态分析</span>
                        <span className="english-name">Static Analyse</span>
                    </div>
                </div>
                <div className="tabs-container">
                    <div className="card-container">
                        <div>
                            <div className="sub_title" id="static-feature">静态特征</div>
                            <div>
                                <StaticFeature signatures={signatures}></StaticFeature>
                            </div>
                        </div>
                        {/* <Collapse defaultActiveKey={['1', '2']} ghost>
                            <Panel header="静态特征" key="2" id="static-feature">
                                <StaticFeature signatures={signatures}></StaticFeature>
                            </Panel>
                        </Collapse> */}

                    </div>
                </div>
            </div>
        );
    }
}

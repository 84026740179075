// @ts-nocheck
import { Component } from 'react';
import axios from './api/index'
import { Table, Tag, message, Typography } from "antd";
import Moment from "moment";
import { Redirect, Link } from "react-router-dom";
import { getToken } from "./Login_old";
import './css/login.css'
import { formatDate } from './utils/index';

console.log(getToken())

const { Column } = Table
// const {  Link:TextLink } = Typography;


export default class List extends Component<any, any> {
    state = {
        data: [],
        loading: true,
        error: false,
    }

    componentWillMount() {
        axios.get('/analyses/', { params: { ordering: '-created_on' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response)
                    this.setState({ data: response.data.results, loading: false })
                } else {
                    this.setState({ loading: false, error: true })
                    // TODO 异常处理
                }
            })

    }
    toDetail = (id) => {
        console.log('todetail', id)
        this.props.history.push({
            pathname: '/detail',
            state: { id },
            query: { id: id },
        })
        // this.props.history.push(`/detail/${id}`)
    }


    render() {
        const { data, loading } = this.state;
        const scoreHuman = (size: number) => {
            if (size <= 4) return <Tag color="blue">{size}</Tag>;
            if (size <= 7) return <Tag color="yellow">{size}</Tag>;
            return <Tag color="red">{size}</Tag>;
        }
        const statusHuman = (status: string) => {
            if (status === 'reported') return '报告已生成'
        }
        const TimeHuman = (record: any, text: any) => {
            return Moment(Date.parse(record)).format()
        }
        const token = getToken()
        if (!token) { message.info("请登录后查看") }

        return (

            !getToken() ? <Redirect to="/login" /> :
                // false ? <Redirect to="/Login"/> :
                <div className='list'>
                    <Table dataSource={data} loading={loading} rowKey="id">
                        {/* <Column className="listcolumn" title="样本名称" dataIndex={["target", 'md5']} align='center'
                                render={(text, record: { hash_id: string }) => <a
                                    href={"/#/detail/" + record.hash_id}>{text}</a>}/> */}
                        {/* <Column className="listcolumn" title="MD5" dataIndex={["target", 'md5']} align='center'
                            render={(text, record) => <TextLink
                                onClick={() => this.toDetail(record.id)}>{text}</TextLink>} /> */}
                        <Column className="listcolumn" title="MD5" dataIndex={["target", 'md5']} align='center'
                            render={(text, record) => <Link to={"/detail/" + record.id}>{text}</Link>} />
                        <Column className="listcolumn" title="创建时间" dataIndex="created_on" key="created_on" width={230} align='center' render={(text) => formatDate(text)} />
                        <Column className="listcolumn" title="ID" dataIndex="id" key="id" align='center' />
                        <Column className="listcolumn" title="kind" dataIndex="kind" key="kind" align='center' />
                        <Column className="listcolumn" title="score" dataIndex="score" key="score" align='center' />
                        <Column className="listcolumn" title="state" dataIndex="state" key="state" align='center' />
                        {/* <Column className="listcolumn" title="MD5" dataIndex="md5" key="md5" align='center'/> */}
                        {/*<Column title="系统环境" dataIndex="platform" key="platform" width={150}/>*/}
                        {/* <Column className="listcolumn" title="文件类型" dataIndex="file_type" key="file_type"
                                align='center'/>
                        <Column className="listcolumn" title="进度" dataIndex="status" key="file_type" width={120}
                                render={statusHuman} align='center'/>
                        <Column className="listcolumn" title="危险评分" dataIndex="score" key="file_type" width={100}
                                render={scoreHuman} align='center'/>

                        <Column className="listcolumn" title="创建时间" dataIndex="added_on" key="added_on" width={230}
                                render={TimeHuman} align='center'/> */}
                    </Table>
                </div>
        );
    }
}

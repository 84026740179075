export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_SEARCH_TAG = 'SET_SEARCH_TAG';
export const SET_SEARCH_FAMILY = 'SET_SEARCH_FAMILY';
export const FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_CREATED_ON_START = 'SET_CREATED_ON_START';
export const SET_CREATED_ON_END = 'SET_CREATED_ON_END';
export const SET_SCORE_START = 'SET_SCORE_START';
export const SET_SCORE_END = 'SET_SCORE_END';

export const VOTE_SUP = 'VOTE_SUP';

export const voteSup = () => ({
  type: VOTE_SUP,
});

export const setSearchQuery = (query) => ({
  type: SET_SEARCH_QUERY,
  payload: query,
});

export const setSearchTag = (tag) => ({
  type: SET_SEARCH_TAG,
  payload: tag,
});

export const setSearchFamily = (family) => ({
  type: SET_SEARCH_FAMILY,
  payload: family,
});

export const fetchSearchResults = (params) => ({
  type: FETCH_SEARCH_RESULTS,
  payload: params,
});

export const setUsernameAction = (params) => ({
  type: SET_USERNAME,
  payload: params,
});

export const setCreatedOnStart = (params) => ({
  type: SET_CREATED_ON_START,
  payload: params,
});

export const setCreatedOnEnd = (params) => ({
  type: SET_CREATED_ON_END,
  payload: params,
});

export const setScoreStart = (params) => ({
  type: SET_SCORE_START,
  payload: params,
});

export const setScoreEnd = (params) => ({
  type: SET_SCORE_END,
  payload: params,
});
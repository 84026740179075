// @ts-nocheck
import CryptoJS from 'crypto-js';

const CHARSET: string[] = Array.from({ length: 256 }, (_, i) => String.fromCharCode(i));
const SPECIAL_CHARACTER: string = '~!@#$%^&()-_+=,.;?\'{}[]';

function generateRandomSalt(): string {
  return Array(8)
    .fill(null)
    .map(() => CHARSET[Math.floor(Math.random() * CHARSET.length)])
    .join('');
}

function pad(password: string, paddingLength: number): string {
  return password + Array(paddingLength)
    .fill(null)
    .map(() => CHARSET[Math.floor(Math.random() * CHARSET.length)])
    .join('');
}

function padding(paddingLength: number): string {
  let padding = '';
  for (let i = 0; i < paddingLength; i++) {
      padding += CHARSET[Math.floor(Math.random() * CHARSET.length)];
  }
  return padding;
}

function generatePassword(): string {
  const letterLength: number = Math.floor(Math.random() * 7) + 4;
  const letters: string[] = Array(letterLength)
    .fill(null)
    .map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 65));
  const digits: string[] = Array(2)
    .fill(null)
    .map(() => Math.floor(Math.random() * 10).toString());
  const specials: string[] = Array(2)
    .fill(null)
    .map(() => SPECIAL_CHARACTER[Math.floor(Math.random() * SPECIAL_CHARACTER.length)]);
  return letters.join('') + specials.join('') + digits.join('');
}

function encryptPassword(password: string, key: string = "fN-(vbuWsv_P9eUQ"): string {
  const keyHex: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(key);
  const salt: string = generateRandomSalt();
  console.log('salt', salt, salt.length)
  // const paddedPassword: string = pad(salt + password, 16 - (2 + 8 + password.length) % 16);
  const paddingLength = 16 - (2 + 8 + password.length) % 16
  const paddingString = padding(paddingLength)
  const paddedPassword = salt + password + paddingString + ('0' + password.length.toString()).slice(-2)
  console.log('paddedPassword', paddedPassword)
  const encrypted: CryptoJS.lib.CipherParams = CryptoJS.AES.encrypt(paddedPassword, keyHex, { mode: CryptoJS.mode.ECB });
  // const keyrandom = CryptoJS.lib.WordArray.random(16); // 生成128位随机密钥
  // const iv = CryptoJS.lib.WordArray.random(16); // 生成128位随机初始化向量
  // const encrypted: CryptoJS.lib.CipherParams = CryptoJS.AES.encrypt(paddedPassword, keyrandom, { iv: iv });
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
}

/**
 * getKey () returns a string
 * @return {String} 随机生成的 key
 */
export const getKey = function () {
  const nums = [5, 7, 6, 6, 7, 7, 6, 5]
  const times = [33, 43, 11, 19, 17, 19, 17, 37]
  let result = ''
  let tmp = ''

  for (let i = 0; i < 8; i++) {
      tmp = Math.pow(2, nums[i])
      for (let j = 0; j < times[i]; j++) {
          tmp = (Math.round(tmp / 2) + 1) * 2
      }
      result += String.fromCharCode(Math.round(tmp / 2))
  }

  for (let k = 7; k > -1; k--) {
      tmp = Math.pow(2, nums[k])
      for (let l = 0; l < times[k]; l++) {
          tmp = (Math.round(tmp / 2) + 1) * 2
      }
      result += String.fromCharCode(Math.round(tmp / 2))
  }
  return result
}

/**
* getToken () returns a string
* @return {String} 随机生成的 混淆后缀
*/
export const getSuffix = function (len = 8) {
  let template = ''
  let tokenSource = ''
  for (let i = 0; i < 128; i++) {
      template += String.fromCharCode(i)
  }
  for (let j = 0; j < len; j++) {
      tokenSource += template[Math.round(Math.random() * (template.length - 1))]
  }

  return tokenSource
}

/**
* @param {String} word 需要加密的明文
* @param {String} needPad 是否需要自动补全
* @return {String} 加密之后的密文
*/
export const encrypt = function (word, needPad = true) {
  // 固定数组和对象数据
  if (word.constructor !== String) {
      word = JSON.stringify(word)
  }

  // 转换为 utf-8 字符串
  const key = CryptoJS.enc.Utf8.parse(getKey())
  const srcs = CryptoJS.enc.Utf8.parse(word)

  // 配置参数
  const config = {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
  }

  if (!needPad) {
      config.padding = CryptoJS.pad.NoPadding
  }

  // 加密
  const encrypted = CryptoJS.AES.encrypt(srcs, key, config)

  // 转换成 hex
  const hexStr = encrypted.ciphertext.toString().toUpperCase()

  return hexStr
}

/**
* @param {String} key 解密所用密钥
* @param {String} word 需要解密的密文
* @return {String} 解密之后的明文
*/
export const decrypt = function (word, needPad = true, encry = 'Utf8') {
  // 转换成 utf-8 字符串
  const key = CryptoJS.enc.Utf8.parse(getKey())

  const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)

  // 配置参数
  const config = {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
  }

  if (!needPad) {
      config.padding = CryptoJS.pad.NoPadding
  }
  // 解密
  const decrypt = CryptoJS.AES.decrypt(srcs, key, config)

  // 判断使用那种编码进行转换
  let decryptedStr = null

  switch (true) {
      case encry === 'Utf8':
          decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
          break
      case encry === 'Latin1':
          decryptedStr = decrypt.toString(CryptoJS.enc.Latin1)
          break
      case encry === 'Base64':
          decryptedStr = decrypt.toString(CryptoJS.enc.Base64)
          break
      case encry === 'Hex':
          decryptedStr = decrypt.toString(CryptoJS.enc.Hex)
          break
      case encry === 'Utf16':
          decryptedStr = decrypt.toString(CryptoJS.enc.Utf16)
          break
      case encry === 'Utf16BE':
          decryptedStr = decrypt.toString(CryptoJS.enc.Utf16BE)
          break
      case encry === 'Utf16LE':
          decryptedStr = decrypt.toString(CryptoJS.enc.Utf16LE)
          break
      default:
          decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
          break
  }

  let result = decryptedStr.toString()

  // 解析对象和数组数据
  if (/(\[[\S\s]*\])|({[\S\s]*})/g.test(result)) {
      result = JSON.parse(JSON.stringify(result))
  }

  return result
}

// 解密密码 suffixLen 是盐的长度
export const decryptPassword = (password, suffixLen = 8) => {
  const decryptStr = decrypt(password, false, 'Latin1')
  const passLen = Number(decryptStr.slice(decryptStr.length - 2))
  const decryptedPass = decryptStr.slice(suffixLen, passLen + suffixLen)

  return decryptedPass
}

// 加密密码 8位盐 + 密码 + 随机填充 + 2位密码长度(十进制字符串)
// 随机填充的长度计算：16 - ((密码的长度 + 2（表示密码长度的数值） + 8（盐的长度）) % 16)
export const encryptPwd = function (password = null, suffixLen = 8) {
  const passLen = password.length
  const lenSuffix = passLen < 10 ? `0${passLen}` : `${passLen}`
  const afterSuffix = 16 - ((passLen + lenSuffix.length + suffixLen) % 16)
  const temp = `${getSuffix(suffixLen)}${password}${getSuffix(afterSuffix)}${lenSuffix}`
  const encryptedPassword = encrypt(temp, false)

  return encryptedPassword
}

export {
  encryptPassword,
  generatePassword,
  generateRandomSalt,
  pad,
  SPECIAL_CHARACTER,
  CHARSET,
};
// @ts-nocheck
import { Component } from "react";
import { Table, Tabs, Tag, Timeline, Collapse, Empty, List } from "antd";
import ExpandableContent from './ExpandableContent'
import FeatureDisplay from "./FeatureDisplay";
import BasicInfo from "./BasicInfo";
import ProcessTree from "./ProcessTree";
import { Screenshot } from './Screenshot';
import { NetworkBehavior } from './NetworkBehavior';
import exchangeFundsLineIcon from "../images/Icon/exchange-funds-line.svg";
import { FundProjectionScreenOutlined } from '@ant-design/icons';


const { Panel } = Collapse
const { TabPane } = Tabs;

export class DynamicAnalyse extends Component<any, any> {
    render() {
        const { signatures, processes, id, screenshot, task_id, network } = this.props
        console.log('DynamicAnalyse props', this.props)

        return (
            <div className="network-behavior v-card">
                <div className="header">
                    {/* <img src={exchangeFundsLineIcon} alt="" style={{ width: '24px' }} /> */}
                    <FundProjectionScreenOutlined className='header_icon' />
                    <div>
                        <span className="header-name">动态分析</span>
                        <span className="english-name">Dynamic Analyse</span>
                    </div>
                </div>
                <div className="tabs-container">
                    <div className="card-container">
                        <div>
                            <div className="sub_title" id="dynamic-feature">动态特征</div>
                            <div>
                                <FeatureDisplay signatures={signatures}></FeatureDisplay>
                            </div>
                        </div>
                        <div>
                            <div className="sub_title" id="dynamic-process-behavior">进程行为</div>
                            <div>
                                <ProcessTree processes={processes} />
                            </div>
                        </div>
                        <div>
                            <div className="sub_title" id="dynamic-network-behavior">网络行为</div>
                            <div>
                                <NetworkBehavior network={network} />
                            </div>
                        </div>
                        <div>
                            <div className="sub_title" id="dynamic-screenshot">运行截图</div>
                            <div>
                                <Screenshot screenshot={screenshot} id={id} task_id={task_id} />
                            </div>
                        </div>
                        {/* <Collapse defaultActiveKey={['1', '2', '3', '4']} ghost>
                            <Panel header="动态特征" key="1" id="dynamic-feature">
                                <FeatureDisplay signatures={signatures}></FeatureDisplay>
                            </Panel>
                            <Panel header="进程行为" key="2" id="dynamic-process-behavior">
                                <ProcessTree processes={processes} />
                            </Panel>
                            <Panel header="网络行为" key="3" id="dynamic-network-behavior">
                                <NetworkBehavior network={network} />
                            </Panel>
                            <Panel header="运行截图" key="4" id="dynamic-screenshot">
                                <Screenshot screenshot={screenshot} id={id} task_id={task_id} />
                            </Panel>
                        </Collapse> */}

                    </div>
                </div>
            </div>
        );
    }
}

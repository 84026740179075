// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography } from 'antd';

const { Text } = Typography;

// 自定义展开/收起内容组件
const ExpandableContent = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const [showExpand, setShowExpand] = useState(false);
  const contentRef = useRef(null); // 创建一个ref来引用内容div
  
  const toggleExpand = () => setExpanded(!expanded);

  // 样式对象，用于控制内容区域的高度
  const contentStyle = {
    overflow: 'hidden',
    maxHeight: expanded ? 'none' : '400px', // 折叠时的最大高度，可根据需要调整
    transition: 'max-height 0.3s ease-in-out',
  };

  // 在组件挂载后获取内容的高度
  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      if (contentHeight <= 400) {
        setExpanded(true); // 如果内容高度小于等于最大高度，则默认展开
      } else {
        setShowExpand(true) // 如果内容高度大于最大高度，则显示展开按钮
      }
    }
  }, []);

  return (
    <div>
      {/* 使用一个div来包裹内容，应用高度限制样式 */}
      <div ref={contentRef} style={contentStyle}>
        {children}
      </div>
      {/* 当内容高度超过最大高度时才显示按钮 */}
      {showExpand && (
        <Button type="link" onClick={toggleExpand}>
          {expanded ? '收起' : '展开更多'}
        </Button>
      )}
    </div>
  );
};

export default ExpandableContent;